import React, { createRef, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { checkMailInDB, createAccount, } from "../../../apiCalls/authApiCalls";
import { getAllCountries } from "../../../apiCalls/userApiCalls";
import { validateMailEvent, validateMailRef, validateMailValue } from "../../../Components/Helpers/mailValidation";
import { validatePhoneRef } from "../../../Components/Helpers/phoneValidation";
import scrollToTop from "../../../Components/Helpers/scrollToTop";
import { translationMix } from "../../../Components/Helpers/translationMix";
import { Loader } from "../../../Components/Loader/loader";
import LoaderAnimation from "../../../Components/LoaderAnimation/loaderAnimation";
import RegisterSlider from "../../../Components/Sliders/Register/registerSlider";
import styles from "./create.module.scss"

function Create({ isFastForm = false, isAccount = false, setOption, userData, setUserData }) {
    const [mail, setMail] = useState(null)
    const [countries, setCountries] = useState(null)
    const [isCreadted, setIsCreadted] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [errorTimeOut, setErrorTimeOut] = useState([])
    const mailError = useRef(null)
    const listInputRef = useRef(null)
    const listRef = useRef(null)
    const listRef2 = useRef(null)
    const inputsRefs = useRef([])
    const sliderRef = useRef(null)
    const secondRef = useRef(null)
    const password1Ref = useRef(null)
    const password2Ref = useRef(null)
    const passwordErrorRef = useRef(null)
    const { lang } = useParams();

    const arrLength = 14
    if (inputsRefs.current.length !== arrLength) {
        // add or remove refs
        inputsRefs.current = Array(arrLength)
            .fill()
            .map((_, i) => inputsRefs.current[i] || createRef());
    }

    function getAllData() {
        if (validateData()) {
            let genderValue = 'null'
            if (inputsRefs.current[1].current.checked)
                genderValue = 'male'
            else if (inputsRefs.current[2].current.checked)
                genderValue = 'female'

            let countryId = ''
            for (let i = 0; i < countries.length; i++) {
                if (countries[i].name.toLowerCase() === inputsRefs.current[9].current.value.toLowerCase()) {
                    countryId = countries[i].id
                    break
                }
            }
            const myJsonData = {
                email: inputsRefs.current[0].current.value,
                gender: genderValue,
                firstname: inputsRefs.current[3].current.value,
                lastname: inputsRefs.current[4].current.value,
                phone: inputsRefs.current[5].current.value,
                company_name: inputsRefs.current[6].current.value,
                street_number: inputsRefs.current[7].current.value,
                zip_code: inputsRefs.current[8].current.value,
                id_country: countryId,
                country_name: inputsRefs.current[9].current.value,
                street: inputsRefs.current[10].current.value,
                apartament_number: inputsRefs.current[11].current.value,
                city: inputsRefs.current[12].current.value,
                passwd: password1Ref.current.value,
                confirm_passwd: password2Ref.current.value
            }
            return myJsonData
        }
        else
            return JSON.parse(null)
    }

    /** This is in useEffect */
    // function setAllValuesInInputs() {
    //     if (userData) {
    //         inputsRefs.current[0].current.value = userData.email
    //         if (userData.gender === "male") {
    //             inputsRefs.current[1].current.checked = true;
    //             inputsRefs.current[2].current.checked = false;
    //         } if (userData.gender === "female") {
    //             inputsRefs.current[1].current.checked = false;
    //             inputsRefs.current[2].current.checked = true;
    //         }
    //         inputsRefs.current[3].current.value = userData.firstname
    //         inputsRefs.current[4].current.value = userData.lastname
    //         inputsRefs.current[5].current.value = userData.phone
    //         inputsRefs.current[6].current.value = userData.company_name
    //         inputsRefs.current[7].current.value = userData.street_number
    //         inputsRefs.current[8].current.value = userData.zip_code
    //         inputsRefs.current[9].current.value = userData.country_name
    //         inputsRefs.current[10].current.value = userData.street
    //         inputsRefs.current[11].current.value = userData.apartament_number
    //         inputsRefs.current[12].current.value = userData.city
    //         inputsRefs.current[13].current.checked = true
    //         for (let i = 0; i <= 10; i++) {
    //             if (i === 4) {
    //                 if (userData.company_name.length > 0) {
    //                     hideLabelByIndex(i)
    //                     showHideOkById(i)
    //                 }
    //             }
    //             else if (i === 9) {
    //                 if (userData.apartament_number.length > 0) {
    //                     hideLabelByIndex(i)
    //                     showHideOkById(i)
    //                 }
    //             } else {
    //                 hideLabelByIndex(i)
    //                 showHideOkById(i)
    //             }
    //         }
    //         validateData()
    //     }
    // }

    useEffect(() => {
        if (localStorage.getItem("userCorrectMail")) {
            if (validateMailValue(localStorage.getItem("userCorrectMail"))) {
                setMail(localStorage.getItem("userCorrectMail"))
                inputsRefs.current[0].current.value = localStorage.getItem("userCorrectMail");
                localStorage.removeItem("userCorrectMail")
                const readInput = document.querySelector(`[data-label~="${0}"]`)
                const readOk = document.querySelector(`[data-ok~="${0}"]`)
                readInput.classList.add(styles.hide)
                readOk.classList.add(styles.iconShow)
            } else {
                localStorage.removeItem("userCorrectMail")
                setMail(' ')
            }
        } else {
            setMail(' ')
        }
    }, [])

    useEffect(() => {
        getAllCountries().then(json => {
            setCountries(json)
        })
    }, [])

    useEffect(() => {
        if (countries) {
            if (userData) {
                inputsRefs.current[0].current.value = userData.email
                if (userData.gender === "male") {
                    inputsRefs.current[1].current.checked = true;
                    inputsRefs.current[2].current.checked = false;
                } if (userData.gender === "female") {
                    inputsRefs.current[1].current.checked = false;
                    inputsRefs.current[2].current.checked = true;
                }
                inputsRefs.current[3].current.value = userData.firstname
                inputsRefs.current[4].current.value = userData.lastname
                inputsRefs.current[5].current.value = userData.phone
                inputsRefs.current[6].current.value = userData.company_name
                inputsRefs.current[7].current.value = userData.street_number
                inputsRefs.current[8].current.value = userData.zip_code
                inputsRefs.current[9].current.value = userData.country_name
                inputsRefs.current[10].current.value = userData.street
                inputsRefs.current[11].current.value = userData.apartament_number
                inputsRefs.current[12].current.value = userData.city
                inputsRefs.current[13].current.checked = true
                for (let i = 0; i <= 10; i++) {
                    if (i === 4) {
                        if (userData.company_name.length > 0) {
                            hideLabelByIndex(i)
                            showHideOkById(i)
                        }
                    }
                    else if (i === 9) {
                        if (userData.apartament_number.length > 0) {
                            hideLabelByIndex(i)
                            showHideOkById(i)
                        }
                    } else {
                        hideLabelByIndex(i)
                        showHideOkById(i)
                    }
                }
                // validateData()
            }
        }
    }, [countries, userData])

    function hideLabel(event, index) {
        const readInput = document.querySelector(`[data-label~="${index}"]`)
        if (event.target.value) {
            if (!readInput.classList.contains(styles.hide))
                readInput.classList.add(styles.hide)
        } else {
            readInput.classList.remove(styles.hide)
        }
    }

    function hideLabelByIndex(index) {
        const readLabel = document.querySelector(`[data-label~="${index}"]`)
        if (!readLabel.classList.contains(styles.hide))
            readLabel.classList.add(styles.hide)
    }

    function showHideOkMail(event, index) {
        const readOk = document.querySelector(`[data-ok~="${index}"]`)
        if (validateMailEvent(event)) {
            if (!readOk.classList.contains(styles.iconShow))
                readOk.classList.add(styles.iconShow)
            event.target.parentElement.classList.remove(styles.borderRed)
        } else
            readOk.classList.remove(styles.iconShow)
    }

    function showHideOkReq(event, index) {
        const readOk = document.querySelector(`[data-ok~="${index}"]`)
        if (event.target.value) {
            if (!readOk.classList.contains(styles.iconShow))
                readOk.classList.add(styles.iconShow)
            event.target.parentElement.classList.remove(styles.borderRed)
        } else
            readOk.classList.remove(styles.iconShow)
    }

    function showHideOkMin(event, index) {
        const readOk = document.querySelector(`[data-ok~="${index}"]`)
        if (event.target.value) {
            event.target.parentElement.classList.remove(styles.borderRed)
            if (event.target.value.length > 3) {
                if (!readOk.classList.contains(styles.iconShow))
                    readOk.classList.add(styles.iconShow)
            } else
                readOk.classList.remove(styles.iconShow)
        } else
            readOk.classList.remove(styles.iconShow)
    }

    function showHideOkById(index) {
        const readOk = document.querySelector(`[data-ok~="${index}"]`)
        if (!readOk.classList.contains(styles.iconShow))
            readOk.classList.add(styles.iconShow)

    }

    function validateData(arrayTextError = []) {
        //check required
        let valid = true;
        valid = checkCountry();
        if (valid === false) {
            arrayTextError.push(translationMix(['account', 'create', 'error', '3']))
        }
        for (let i = 0; i < arrLength; i++) {
            if (i === 0) {
                if (!validateMailRef(inputsRefs.current[i])) {
                    if (!inputsRefs.current[i].current.parentElement.classList.contains(styles.borderRed))
                        inputsRefs.current[i].current.parentElement.classList.add(styles.borderRed)
                    valid = false
                } else {
                    inputsRefs.current[i].current.parentElement.classList.remove(styles.borderRed)
                }
            } else if (i === 5) {
                if (!validatePhoneRef(inputsRefs.current[i])) {
                    inputsRefs.current[i].current.parentElement.classList.add(styles.borderRed)
                    arrayTextError.push(translationMix(['account', 'create', 'error', '4']))
                    valid = false
                }
                else {
                    inputsRefs.current[i].current.parentElement.classList.remove(styles.borderRed)
                }
            }
            else if (inputsRefs.current[i].current.required) {
                if (i === 13) {
                    if (!inputsRefs.current[i].current.checked) {
                        arrayTextError.push(translationMix(['account', 'create', 'error', '5']))
                        valid = false;
                        if (!inputsRefs.current[i].current.classList.contains(styles.colorRed))
                            inputsRefs.current[i].current.classList.add(styles.colorRed)
                    }
                }
                if (!inputsRefs.current[i].current.value) {
                    valid = false;
                    if (!inputsRefs.current[i].current.parentElement.classList.contains(styles.borderRed))
                        inputsRefs.current[i].current.parentElement.classList.add(styles.borderRed)
                } else if (i !== 9) {
                    inputsRefs.current[i].current.parentElement.classList.remove(styles.borderRed)
                }
            }
        }
        errorTimeOut.forEach(element => {
            clearTimeout(element)
        });
        if (mailError.current) {
            mailError.current.innerText = arrayTextError.join(", ")
            mailError.current.classList.add(styles.red)
        }
        const myTimeOut1 = setTimeout(() => {
            if (mailError.current)
                mailError.current.classList.remove(styles.red)
        }, 3000)
        const myTimeOut2 = setTimeout(() => {
            if (mailError.current)
                mailError.current.innerText = ''
        }, 3300)
        setErrorTimeOut([myTimeOut1, myTimeOut2])
        return valid;
    }

    function checkCountry() {
        const readOk = document.querySelector(`[data-ok~="${7}"]`)
        for (let i = 0; i < countries.length; i++) {
            if (inputsRefs.current[9].current.value.toLowerCase() === countries[i].name.toLowerCase()) {
                if (!readOk.classList.contains(styles.iconShow))
                    readOk.classList.add(styles.iconShow)
                inputsRefs.current[9].current.parentElement.classList.remove(styles.borderRed)
                return true;
            }
            else if (i === countries.length - 1) {
                readOk.classList.remove(styles.iconShow)
                if (!inputsRefs.current[9].current.parentElement.classList.contains(styles.borderRed))
                    inputsRefs.current[9].current.parentElement.classList.add(styles.borderRed)
                return false;
            }
        }
    }

    function showHints(event) {
        let readValue = null;
        if (listInputRef.current)
            readValue = listInputRef.current.value.toLowerCase();
        // if (!readValue)
        //     listRef.current.classList.remove(styles.searchListOpen);
        // else {
        listRef.current.classList.add(styles.searchListOpen);
        listRef2.current.classList.add(styles.searchHintsOpen);
        // let counter = 0;
        listRef2.current.innerHTML = '';
        for (let i = 0; i < countries.length; i++) {
            if (readValue !== null && readValue.length > 0) {
                if (countries[i].name.toLowerCase().startsWith(readValue)) {
                    const element = document.createElement('span');
                    element.classList.add(styles.searchElement);
                    element.innerText = countries[i].name;

                    element.onclick = function () {
                        inputsRefs.current[9].current.value = countries[i].name;
                        hideLabel(event, 7);
                        checkCountry(event)
                        listRef.current.classList.remove(styles.searchListOpen);
                    }
                    listRef2.current.insertAdjacentElement('beforeend', element);
                }
            } else if (countries[i].name.toLowerCase()) {
                // counter++;
                const element = document.createElement('span');
                element.classList.add(styles.searchElement);
                element.innerText = countries[i].name;

                element.onclick = function () {
                    inputsRefs.current[9].current.value = countries[i].name;
                    hideLabel(event, 7);
                    checkCountry(event)
                    listRef.current.classList.remove(styles.searchListOpen);
                }
                listRef2.current.insertAdjacentElement('beforeend', element);
            }
            // if (counter > 10) {
            //     break;
            // }
        }
        // if (counter === 0) {
        //     listRef.current.classList.remove(styles.searchListOpen);
        // }
        // }
    }

    function showHintsSearch(event) {
        const readValue = event.target.value.toLowerCase();
        listRef.current.classList.add(styles.searchListOpen);
        listRef2.current.classList.add(styles.searchHintsOpen);
        let counter = 0;
        listRef2.current.innerHTML = '';
        for (let i = 0; i < countries.length; i++) {
            if (countries[i].name.toLowerCase().startsWith(readValue)) {
                counter++;
                const element = document.createElement('span');
                element.classList.add(styles.searchElement);
                element.innerText = countries[i].name;

                element.onclick = function () {
                    inputsRefs.current[9].current.value = countries[i].name;
                    hideLabel(event, 7);
                    checkCountry(event)
                    listRef.current.classList.remove(styles.searchListOpen);
                }
                listRef2.current.insertAdjacentElement('beforeend', element);
            }
            if (counter > 10) {
                break;
            }
        }
        // if (counter === 0) {
        //     listRef.current.classList.remove(styles.searchListOpen);
        // }

    }

    function hideList() {
        listRef.current.classList.remove(styles.searchListOpen);
        listRef2.current.classList.remove(styles.searchListOpen);
    }

    function validateFirstPage() {
        if (isFastForm) {
            setIsLoading(true)
            //for loading animation
            setTimeout(() => {
                let myData = getAllData()
                setIsLoading(false)
                if (myData) {
                    setUserData(myData)
                    setOption(2)
                }
            }, 500)
            return false;
        }
        else {
            let tmpArrayEndErrorText = []
            if (inputsRefs.current[0].current.value.length > 0) {
                setIsLoading(true)
                checkMailInDB(inputsRefs.current[0].current.value).then(check => {
                    //check - mail is in DB
                    if (check) {
                        if (!inputsRefs.current[0].current.parentElement.classList.contains(styles.borderRed))
                            inputsRefs.current[0].current.parentElement.classList.add(styles.borderRed)
                        tmpArrayEndErrorText.push(translationMix(['account', 'create', 'error', '0']))
                        validateData(tmpArrayEndErrorText)
                        setIsLoading(false)
                    } else {
                        if (validateData()) {
                            goNext(secondRef)
                            setIsLoading(false)
                            scrollToTop(200, 1000);
                        } else {
                            setIsLoading(false)
                            return false;
                        }
                    }
                })
            }
            validateData()
        }
    }

    function uncheckBox(index) {
        if (inputsRefs.current[index].current.checked)
            inputsRefs.current[index].current.checked = false;
    }

    function goNext(ref) {
        ref.current.classList.remove(styles.hide)
        sliderRef.current.go(1)
    }

    function goBack() {
        sliderRef.current.go(0)
    }

    function validatePasswords() {
        if (password1Ref.current.value.length > 3) {
            if (password1Ref.current.value === password2Ref.current.value) {
                return true;
            } else {
                passwordErrorRef.current.classList.add(styles.red)
                passwordErrorRef.current.innerText = translationMix(['account', 'create', 'error', '2'])
                setTimeout(() => {
                    passwordErrorRef.current.classList.remove(styles.red)
                }, 3000)
                return false;
            }
        }
        else {
            passwordErrorRef.current.classList.add(styles.red)
            passwordErrorRef.current.innerText = translationMix(['account', 'create', 'error', '1'])
            setTimeout(() => {
                passwordErrorRef.current.classList.remove(styles.red)
            }, 3000)
            return false;
        }
    }

    function createNewUser() {
        let myData = getAllData()
        if (myData !== null) {
            createAccount(myData).then(end => {
                const res = end[0]
                const json = end[1]
                if (res.status === 200) {
                    if (json.status) {
                        if (json.status === true) {
                            setIsCreadted(true);
                            scrollToTop(200, 500)
                            return true;
                        } else if (json.startsWith('Error')) {
                            setIsCreadted(false);
                            return false;
                        } else {
                            setIsCreadted(false);
                            return false;
                        }
                    }
                    else if (json.startsWith('Error')) {
                        setIsCreadted(false);
                        return false;
                    } else {
                        setIsCreadted(true);
                        return false;
                    }
                } else {
                    setIsCreadted(false);
                    return false;
                }
            });
        }
    }

    return (<>
        <Helmet>
            <title>{lang === 'pl' ? 'Bizserver - stwórz nowe konto' : lang === 'en' ? 'Bizserver - create a new account' : 'Bizserver - ein neues Konto erstellen'}</title>
            <meta name="description" content={"Bizserver - Aktywacja konta"} />
        </Helmet>
        {isFastForm || isAccount ? null : <Loader data={mail} />}
        {!isCreadted ?
            <RegisterSlider sliderRef={sliderRef}>
                <div className={styles.container}>
                    <div className={styles.mainContainer}>
                        <div className={styles.main}>
                            <form autoComplete={"on"} noValidate onSubmit={(event) => { event.preventDefault(); validateFirstPage() }} className={styles.vertical}>
                                {isFastForm ? <div className={styles.titleWithImage}>{translationMix(['account', 'create', 'title', '1'])}</div> : <div className={styles.title}>{isAccount ? translationMix(['account', 'create', 'title', '2']) : translationMix(['account', 'create', 'title', '0'])}</div>}
                                <div className={`${styles.error} ${styles.padding}`} ref={mailError}></div>
                                <div className={styles.horizontalMain} style={{ marginBottom: "20px" }}>
                                    <div className={`${styles.verticalLeft}`}>
                                        <div className={styles.text1}>{translationMix(['account', 'create', 'data'])}</div>
                                        <div className={styles.inputContainer}>
                                            <input ref={inputsRefs.current[0]} id={'email'}  className={styles.input} type={"email"} required onInput={(event) => { hideLabel(event, 0); showHideOkMail(event, 0); }} />
                                            <div className={styles.labelContainer}>
                                                <div className={styles.textReq} data-label={0}>{translationMix(['account', 'create', 'lables', '0'])}</div>
                                            </div>
                                            <div className={styles.iconOk} data-ok={0} />
                                        </div>
                                        <div className={`${styles.horizontalCenter} ${styles.paddingSmall}`} style={{ minHeight: "40px" }}>
                                            <div className={styles.text2} style={{ marginRight: "15px" }}>{translationMix(['account', 'create', 'gender', '0'])}</div>
                                            <input ref={inputsRefs.current[1]} type={"checkbox"} className={styles.checkboxRound} onClick={() => uncheckBox(2)}></input>
                                            <div className={styles.text2} style={{ marginRight: "10px" }}>{translationMix(['account', 'create', 'gender', '1'])}</div>
                                            <input ref={inputsRefs.current[2]} type={"checkbox"} className={styles.checkboxRound} onClick={() => uncheckBox(1)}></input>
                                            <div className={styles.text2}>{translationMix(['account', 'create', 'gender', '2'])}</div>
                                        </div>
                                        <div className={styles.inputContainer}>
                                            <input ref={inputsRefs.current[3]} className={styles.input} type={"text"} name={"firstName"} required onInput={(event) => { hideLabel(event, 1); showHideOkReq(event, 1); }} />
                                            <div className={styles.labelContainer}>
                                                <div className={styles.textReq} data-label={1}>{translationMix(['account', 'create', 'lables', '1'])}</div>
                                            </div>
                                            <div className={styles.iconOk} data-ok={1} />
                                        </div>
                                        <div className={styles.inputContainer}>
                                            <input ref={inputsRefs.current[4]} className={styles.input} type={"text"} name={"lastName"} required onInput={(event) => { hideLabel(event, 2); showHideOkReq(event, 2); }} />
                                            <div className={styles.labelContainer}>
                                                <div className={styles.textReq} data-label={2}>{translationMix(['account', 'create', 'lables', '2'])}</div>
                                            </div>
                                            <div className={styles.iconOk} data-ok={2} />
                                        </div>
                                        <div className={styles.inputContainer}>
                                            <input ref={inputsRefs.current[5]} className={styles.input} type={"tel"} name={"phone"} required
                                                onInput={(event) => { hideLabel(event, 3); showHideOkReq(event, 3); }}
                                            // onChange={(event) => {  event.target.value = event.target.value.replace(/\D/, '') }}
                                            />
                                            <div className={styles.labelContainer}>
                                                <div className={styles.textReq} data-label={3}>{translationMix(['account', 'create', 'lables', '3'])}</div>
                                            </div>
                                            <div className={styles.iconOk} data-ok={3} />
                                        </div>
                                    </div>
                                    <div className={styles.verticalRight}>
                                        <div className={styles.text1}>{translationMix(['account', 'create', 'address'])}</div>
                                        <div className={styles.horizontal}>
                                            <div className={styles.vertical}>
                                                <div className={styles.inputContainer}>
                                                    <input ref={inputsRefs.current[6]} className={styles.input} type={"text"} name={'organization'} onInput={(event) => { hideLabel(event, 4); showHideOkReq(event, 4); }} />
                                                    <div className={styles.labelContainer}>
                                                        <div className={styles.text} data-label={4}>{translationMix(['account', 'create', 'lables', '4'])}</div>
                                                    </div>
                                                    <div className={styles.iconOk} data-ok={4} />
                                                </div>
                                                <div className={styles.inputContainer}>
                                                    <input ref={inputsRefs.current[7]} className={styles.input} type={"text"} autoComplete="address-line2" name={"address-one"} required onInput={(event) => { hideLabel(event, 5); showHideOkReq(event, 5); }} />
                                                    <div className={styles.labelContainer}>
                                                        <div className={styles.textReq} data-label={5}>{translationMix(['account', 'create', 'lables', '5'])}</div>
                                                    </div>
                                                    <div className={styles.iconOk} data-ok={5} />
                                                </div>
                                                <div className={styles.inputContainer}>
                                                    <input ref={inputsRefs.current[8]} className={styles.input} type={"text"} name={'postal-code'} required onInput={(event) => { hideLabel(event, 6); showHideOkReq(event, 6); }} />
                                                    <div className={styles.labelContainer}>
                                                        <div className={styles.textReq} data-label={6}>{translationMix(['account', 'create', 'lables', '6'])}</div>
                                                    </div>
                                                    <div className={styles.iconOk} data-ok={6} />
                                                </div>
                                                <div className={styles.inputContainer}>
                                                    <input ref={inputsRefs.current[9]} className={styles.input} readOnly="readonly" type={"text"} required onClick={(event) => { hideLabel(event, 7); checkCountry(); showHints(event); listInputRef.current.focus(); }} />
                                                    <div className={styles.labelContainer}>
                                                        <div className={styles.textReq} data-label={7}>{translationMix(['account', 'create', 'lables', '7'])}</div>
                                                    </div>
                                                    <div className={styles.iconOk} data-ok={7} />
                                                    <div ref={listRef} className={styles.searchListContainer} onBlur={()=>{hideList()}}>
                                                        <div className={styles.inputContainer2}>
                                                            <input ref={listInputRef} className={styles.input2} type={"text"} onInput={(event) => { showHintsSearch(event) }}></input>
                                                            <div className={styles.btnClose} onClick={() => { hideList() }} />
                                                        </div>
                                                        <div className={styles.searchList} ref={listRef2}>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.vertical}>
                                                <div className={styles.inputContainer}>
                                                    <input ref={inputsRefs.current[10]} className={styles.input} type={"text"} autoComplete="address-line1" name={"address-one"} required onInput={(event) => { hideLabel(event, 8); showHideOkReq(event, 8); }} />
                                                    <div className={styles.labelContainer}>
                                                        <div className={styles.textReq} data-label={8}>{translationMix(['account', 'create', 'lables', '8'])}</div>
                                                    </div>
                                                    <div className={styles.iconOk} data-ok={8} />
                                                </div>
                                                <div className={styles.inputContainer}>
                                                    <input ref={inputsRefs.current[11]} className={styles.input} type={"text"} autoComplete="address-line3" name={"address-one"} onInput={(event) => { hideLabel(event, 9); showHideOkReq(event, 9); }} />
                                                    <div className={styles.labelContainer}>
                                                        <div className={styles.text} data-label={9}>{translationMix(['account', 'create', 'lables', '9'])}</div>
                                                    </div>
                                                    <div className={styles.iconOk} data-ok={9} />
                                                </div>
                                                <div className={styles.inputContainer}>
                                                    <input ref={inputsRefs.current[12]} className={styles.input} type={"text"} name={'city'} required onInput={(event) => { hideLabel(event, 10); showHideOkReq(event, 10); }} />
                                                    <div className={styles.labelContainer}>
                                                        <div className={styles.textReq} data-label={10}>{translationMix(['account', 'create', 'lables', '10'])}</div>
                                                    </div>
                                                    <div className={styles.iconOk} data-ok={10} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {isAccount ? null :
                                    <div className={`${styles.horizontalBottom} ${styles.padding}`}>
                                        <input ref={inputsRefs.current[13]} type={"checkbox"} required className={styles.checkbox} onInput={(event) => { event.target.classList.remove(styles.colorRed) }}></input>
                                        <div className={styles.text3}>{translationMix(['account', 'create', 'clause', '0'])} <a href={`/${lang}/privacy-policy`}>{translationMix(['account', 'create', 'clause', '1'])}</a> </div>
                                    </div>}
                                <button type={"submit"} className={`${styles.btn} ${styles.right}`}> {isLoading ? <LoaderAnimation width={20} height={20} isOne={true} /> : isFastForm ? translationMix(['account', 'create', 'btn', '3']) : isAccount ? translationMix(['account', 'create', 'btn', '4']) : translationMix(['account', 'create', 'btn', '0'])}</button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className={`${styles.container} ${styles.hide}`} ref={secondRef}>
                    <div className={styles.mainContainer}>
                        <div className={styles.main}>
                            <form autoComplete={"off"} noValidate onSubmit={(event) => { event.preventDefault(); if (validatePasswords()) createNewUser(); }} className={`${styles.verticalTop} ${styles.maxHeight}`}>
                                <div className={`${styles.horizontal} ${styles.center}`}>
                                    <div className={`${styles.vertical} ${styles.myLimitWidht}`}>
                                        <div className={`${styles.error}`} ref={passwordErrorRef}>{translationMix(['account', 'create', 'error', '1'])}</div>
                                        <div className={styles.text1}>{translationMix(['account', 'create', 'password', '0'])}</div>
                                        <div className={styles.inputContainer}>
                                            <input ref={password1Ref} className={styles.input} type={"password"} required onInput={(event) => { hideLabel(event, 11); showHideOkMin(event, 11); }} />
                                            <div className={styles.labelContainer}>
                                                <div className={styles.textReq} data-label={11}>{translationMix(['account', 'create', 'lables', '11'])}</div>
                                            </div>
                                            <div className={styles.iconOk} data-ok={11} />
                                        </div>
                                        <div className={styles.text1}>{translationMix(['account', 'create', 'password', '1'])}</div>
                                        <div className={styles.inputContainer}>
                                            <input ref={password2Ref} className={styles.input} type={"password"} required onInput={(event) => { hideLabel(event, 12); showHideOkMin(event, 12); }} />
                                            <div className={styles.labelContainer}>
                                                <div className={styles.textReq} data-label={12}>{translationMix(['account', 'create', 'lables', '11'])}</div>
                                            </div>
                                            <div className={styles.iconOk} data-ok={12} />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.buttonContainer}>
                                    <div className={`${styles.btn2}`} onClick={() => goBack()}>{translationMix(['account', 'create', 'btn', '2'])}</div>
                                    <button type={"submit"} className={`${styles.btn2}`} >{translationMix(['account', 'create', 'btn', '1'])}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </RegisterSlider > :
            <div className={styles.endText}>{translationMix(['account', 'create', 'endInfo'])}</div>
        }
    </>
    )

}

export default Create;