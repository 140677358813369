import styles from './small.module.scss'
import { useParams } from 'react-router-dom';
import stringToUrl from '../../Helpers/stringToUrl';
import { translationMix } from '../../Helpers/translationMix';

function Small({ data, isHorizontal = false, isImageBig = false, isMaxHeight = false }) {
    const { lang } = useParams();
    return (
        <div className={`${isHorizontal ? styles.containerHorizontal : styles.containerVertical} ${isImageBig ? '' : styles.maxHeight}`}>
            <div className={`${isImageBig ? styles.imgBig : styles.imgSmall}`} style={{ backgroundImage: `url("${data.image_url}")` }}>
                <div className={styles.shadow} />
            </div>

            <div className={`${isHorizontal ? styles.contentHorizontal : styles.contentVertical}`}>
                {data.tags ? data.tags[0] !== "" ?
                    <div className={styles.tag}>{data.tags.join(", ")}</div> : null : null}
                <div className={`${isHorizontal ? styles.titleHorizontal : styles.titleVertical}`}>{data.title}</div>
                {isHorizontal ?
                    <div className={styles.descriptionContainer}>
                        <div className={styles.description}>{data.description}</div></div>
                    : null}
                <a href={`/${lang}/post/${data.id}-${stringToUrl(data.title)}`} className={`${styles.btnGo} ${isHorizontal ? styles.btnBottom : ''}`}>{translationMix(['shared', 'button', 'read'])}</a>
            </div>
        </div>
    )
}

export default Small