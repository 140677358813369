import {apiUrls} from "../../assets/api/apiUrls";
//very bad - getLang mean get pl or en not full adres of API
function getLang() {

    if(window.location.host === 'localhost:3000') {
        return apiUrls.apiKey.pl;
    } else {
        return apiUrls.apiKey.pl
    }
}

export default getLang()