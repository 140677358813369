import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { getUserAddress } from "../../../apiCalls/userApiCalls";
import { ThemeContext } from "../../../Components/Context/context";
import CustomSelector from "../../../Components/CustomSelector/customSelector";
import { translationMix } from "../../../Components/Helpers/translationMix";
import { Loader } from "../../../Components/Loader/loader";
import BillAddress from "../BillAddress/billAddress";

import styles from './userAddress.module.scss'

function UserAddress() {
    const [createNew, setCreateNew] = useState(null)
    const [adressBillLabel, setAdressBillListNames] = useState(null)
    const [adressBillData, setAdressBillData] = useState(null)
    const [adressBillAllData, setAdressBillAllData] = useState(null)
    const [adressBillSelectedId, setAdressBillSelectedId] = useState(null)
    const [refreshList, setRefreshList] = useState(null)
    const { lang } = useParams()
    const globalContext = useContext(ThemeContext)

    function createNewAddress() {
        setCreateNew(true);
    }

    useEffect(() => {
        if (!adressBillData) {
            getUserAddress(globalContext.authToken).then(res => {
                let tmpArray = []
                res.forEach((item, index) => {
                    tmpArray.push([item.id, `"` + item.company_name + `" ` + item.street + " " + item.street_number])
                })
                setAdressBillListNames(tmpArray)
                setAdressBillAllData(res)
            })
        }
    }, [adressBillData, globalContext.authToken])

    useEffect(() => {
        if (refreshList) {
            getUserAddress(globalContext.authToken).then(res => {
                let tmpArray = []
                res.forEach((item, index) => {
                    tmpArray.push([item.id, `"` + item.company_name + `" ` + item.street + " " + item.street_number])
                })
                setAdressBillListNames(tmpArray)
                setAdressBillAllData(res)
            })
            setRefreshList(false)
        }
    }, [refreshList, globalContext.authToken])


    useEffect(() => {
        if (adressBillSelectedId) {
            for (let i = 0; i < adressBillAllData.length; i++) {
                if (adressBillAllData[i].id === adressBillSelectedId) {
                    setAdressBillData(adressBillAllData[i])
                    break;
                }
            }
        }
    }, [adressBillSelectedId, adressBillAllData])

    return (
        <>
            <Helmet>
                <title>{lang === 'pl' ? 'Bizserver - Twoje konto' : lang === 'en' ? 'Bizserver - Your account' : 'Bizserver - Ihr Konto'}</title>
                <meta name="description" content={"Bizserver - Twoje konto"} />
            </Helmet>
            <Loader data={setAdressBillAllData} />
            {createNew ? <Loader justLoad={true} /> : null}
            <div className={styles.container}>
                <div className={styles.mainContainer}>
                    {createNew ? null : <div className={styles.containerTop}>
                        <div className={styles.selector}>
                            <CustomSelector data={adressBillLabel} returnRealId={true} styleId={2} selectedId={adressBillSelectedId} setSelectorOption={setAdressBillSelectedId} tagText={translationMix(['account', 'userAddress', 'selector', '0'])} />
                        </div>
                        <div className={styles.btn} onClick={() => { createNewAddress() }}>{translationMix(['account', 'userAddress', 'btn'])}</div>
                    </div>}
                </div>
            </div>
            {createNew ? <BillAddress save={true} setUserData={setAdressBillData} setAdressBillSelectedId={setAdressBillSelectedId} create={createNew} setCreate={setCreateNew} /> :
                <BillAddress userData={adressBillData} setUserData={setAdressBillData} save={true} isCart={false} setCreate={setCreateNew} setRefresh={setRefreshList} />}
        </>
    )
}

export default UserAddress;