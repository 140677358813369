import React, { useRef, useState } from "react";
import '@splidejs/react-splide/css/core';

import styles from './gallery.module.scss'
import Preview from "./preview";
import Thumbnail from "./thumbnail";
import FullScreenGallery from "./fullScreenGallery";

function Gallery({ data }) {
    const sliderMain = useRef();
    const sliderThumb = useRef();
    const sliderBig = useRef();
    const [slideActive, setSlideActive] = useState(0)

    const showGallery = () => {
        const fullGallery = document.querySelector(`[data-gallery~="${0}"]`)

        if (fullGallery.classList.contains(styles.show)) {
            fullGallery.classList.remove(styles.show2)
            fullGallery.addEventListener('transitionend', () => {
                fullGallery.classList.remove(styles.show)
                //document.getElementById("root").classList.remove(styles.blockScroll)
            }, { once: true });
        } else {
            //show, setUp and opacity
            fullGallery.classList.add(styles.show)
            sliderBig.current.go(slideActive)

            setTimeout(() => {
                fullGallery.classList.add(styles.show2)
                //document.getElementById("root").classList.add(styles.blockScroll)
            }, 100)
        }
    }

    return (
        <div className={styles.container}>
            {data.length > 0 ? <div className={styles.loopPlus} onClick={() => { showGallery() }}></div> : null}
            <FullScreenGallery data={data} bigRef={sliderBig} showGallery={showGallery} />
            <Preview data={data} forwardRef={sliderMain} setSlideActive={setSlideActive} showGallery={showGallery} />
            <Thumbnail data={data} mainRef={sliderMain} forwardRef={sliderThumb} slideActive={slideActive} />
        </div>
    )
}

export default Gallery