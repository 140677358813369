import React, { createRef, useContext, useEffect, useRef, useState } from "react";
import { createUserAddress, deleteUserAddress, updateUserAddress } from "../../../apiCalls/userApiCalls";
import { apiUrls } from "../../../assets/api/apiUrls";
import { ThemeContext } from "../../../Components/Context/context";
import { validatePhone, validatePhoneRef } from "../../../Components/Helpers/phoneValidation";
import { translationMix } from "../../../Components/Helpers/translationMix";
import LoaderAnimation from "../../../Components/LoaderAnimation/loaderAnimation";
import styles from "./billAddress.module.scss"
import { logout } from "../../../apiCalls/authApiCalls";

function BillAddress({ userData, myId = 1, setUserData, setAdressBillSelectedId, setOpened, save = false, create = false, isCart = true, setCreate, setRefresh = false }) {
    const [countries, setCountries] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [errorPasswordTimeOut, setErrorPasswordTimeOut] = useState([])
    const listInputRef = useRef(null)
    const listRef = useRef(null)
    const listRef2 = useRef(null)
    const errorRef = useRef(null)
    const coverRef = useRef(null)
    const inputsRefs = useRef([])
    const globalContext = useContext(ThemeContext)

    const arrLength = 15
    if (inputsRefs.current.length !== arrLength) {
        // add or remove refs
        inputsRefs.current = Array(arrLength)
            .fill()
            .map((_, i) => inputsRefs.current[i] || createRef());
    }

    function getAllData() {
        if (validateData()) {
            let countryId = ''
            for (let i = 0; i < countries.length; i++) {
                if (countries[i].name.toLowerCase() === inputsRefs.current[9].current.value.toLowerCase()) {
                    countryId = countries[i].id
                    break
                }
            }
            const myJsonData = {
                firstname: inputsRefs.current[3].current.value,
                lastname: inputsRefs.current[4].current.value,
                nip: inputsRefs.current[5].current.value,
                company_name: inputsRefs.current[6].current.value,
                street_number: inputsRefs.current[7].current.value,
                zip_code: inputsRefs.current[8].current.value,
                id_country: countryId,
                country_name: inputsRefs.current[9].current.value,
                street: inputsRefs.current[10].current.value,
                apartament_number: inputsRefs.current[11].current.value,
                city: inputsRefs.current[12].current.value,
                phone: inputsRefs.current[13].current.value
            }
            return myJsonData
        }
        else
            return JSON.parse(null)
    }

    useEffect(() => {
        fetch(`${apiUrls.mainUrl}${apiUrls.countires}`)
            .then((res) => res.json())
            .then((json) => {
                setCountries(json)
            })
    }, [])

    useEffect(() => {
        const myHideLabelByIndex = (index) => {
            const readLabel = document.querySelector(`[data-label~="${index * myId}"]`)
            if (!readLabel.classList.contains(styles.hide))
                readLabel.classList.add(styles.hide)
        }

        const myShowHideOkById = (index) => {
            const readOk = document.querySelector(`[data-ok~="${index * myId}"]`)
            if (!readOk.classList.contains(styles.iconShow))
                readOk.classList.add(styles.iconShow)
        }

        if (countries && userData) {
            let countryName = userData.country_name;
            for (let i = 0; i < countries.length; i++) {
                if (Number(countries[i].id) === Number(userData.id_country)) {
                    countryName = countries[i].name
                    break;
                }
            }
            if (userData) {
                if (userData.firstname)
                    inputsRefs.current[3].current.value = userData.firstname
                if (userData.lastname)
                    inputsRefs.current[4].current.value = userData.lastname
                if (userData.nip)
                    inputsRefs.current[5].current.value = userData.nip
                if (userData.company_name)
                    inputsRefs.current[6].current.value = userData.company_name
                if (userData.street_number)
                    inputsRefs.current[7].current.value = userData.street_number
                if (userData.zip_code)
                    inputsRefs.current[8].current.value = userData.zip_code
                if (countryName)
                    inputsRefs.current[9].current.value = countryName
                if (userData.street)
                    inputsRefs.current[10].current.value = userData.street
                if (userData.apartament_number)
                    inputsRefs.current[11].current.value = userData.apartament_number
                if (userData.city)
                    inputsRefs.current[12].current.value = userData.city
                if (userData.phone)
                    inputsRefs.current[13].current.value = userData.phone
                // inputsRefs.current[13].current.checked = true
                for (let i = 101; i <= 111; i++) {
                    if (i === 101) {
                        if (userData.firstname) {
                            myHideLabelByIndex(i)
                            myShowHideOkById(i)
                        }
                    } else if (i === 102) {
                        if (userData.lastname) {
                            myHideLabelByIndex(i)
                            myShowHideOkById(i)
                        }
                    } else if (i === 103) {
                        if (userData.nip) {
                            if (userData.nip.length > 0) {
                                myHideLabelByIndex(i)
                                myShowHideOkById(i)
                            }
                        }
                    } else if (i === 104) {
                        if (userData.company_name) {
                            if (userData.company_name.length > 0) {
                                myHideLabelByIndex(i)
                                myShowHideOkById(i)
                            }
                        }
                    } else if (i === 105) {
                        if (userData.street_number) {
                            myHideLabelByIndex(i)
                            myShowHideOkById(i)
                        }
                    } else if (i === 106) {
                        if (userData.zip_code) {
                            myHideLabelByIndex(i)
                            myShowHideOkById(i)
                        }

                    } else if (i === 107) {
                        if (countryName) {
                            myHideLabelByIndex(i)
                            myShowHideOkById(i)
                        }
                    } else if (i === 108) {
                        if (userData.street) {
                            myHideLabelByIndex(i)
                            myShowHideOkById(i)
                        }
                    } else if (i === 109) {
                        if (userData.apartament_number) {
                            if (userData.apartament_number.length > 0) {
                                myHideLabelByIndex(i)
                                myShowHideOkById(i)
                            }
                        }
                    } else if (i === 110) {
                        if (userData.city) {
                            myHideLabelByIndex(i)
                            myShowHideOkById(i)
                        }
                    } else if (i === 111) {
                        if (userData.phone) {
                            myHideLabelByIndex(i)
                            myShowHideOkById(i)
                        }
                    }
                }
                //validateData()
            }
        }
        //clean all
        if (!userData) {
            const myShowLabelByIndex = (index) => {
                const readLabel = document.querySelector(`[data-label~="${index * myId}"]`)
                readLabel.classList.remove(styles.hide)
            }
            const myHideOkById = (index) => {
                const readOk = document.querySelector(`[data-ok~="${index * myId}"]`)
                readOk.classList.remove(styles.iconShow)
            }
            inputsRefs.current[3].current.value = null
            inputsRefs.current[4].current.value = null
            inputsRefs.current[5].current.value = null
            inputsRefs.current[6].current.value = null
            inputsRefs.current[7].current.value = null
            inputsRefs.current[8].current.value = null
            inputsRefs.current[9].current.value = null
            inputsRefs.current[10].current.value = null
            inputsRefs.current[11].current.value = null
            inputsRefs.current[12].current.value = null
            inputsRefs.current[13].current.value = null
            for (let i = 101; i <= 111; i++) {
                myShowLabelByIndex(i)
                myHideOkById(i)
            }
        }
    }, [countries, userData, myId])

    function hideLabel(event, index) {
        const readInput = document.querySelector(`[data-label~="${index * myId}"]`)
        if (event.target.value) {
            if (!readInput.classList.contains(styles.hide))
                readInput.classList.add(styles.hide)
        } else {
            readInput.classList.remove(styles.hide)
        }
    }

    // function hideLabelByIndex(index) {
    //     const readLabel = document.querySelector(`[data-label~="${index * myId}"]`)
    //     if (!readLabel.classList.contains(styles.hide))
    //         readLabel.classList.add(styles.hide)
    // }

    // function showLabelByIndex(index) {
    //     const readLabel = document.querySelector(`[data-label~="${index * myId}"]`)
    //     readLabel.classList.remove(styles.hide)
    // }

    function showHideOkReq(event, index) {
        const readOk = document.querySelector(`[data-ok~="${index * myId}"]`)
        if (event.target.value) {
            if (!readOk.classList.contains(styles.iconShow))
                readOk.classList.add(styles.iconShow)
            event.target.parentElement.classList.remove(styles.borderRed)
        } else
            readOk.classList.remove(styles.iconShow)
    }

    // function showHideOkById(index) {
    //     const readOk = document.querySelector(`[data-ok~="${index * myId}"]`)
    //     if (!readOk.classList.contains(styles.iconShow))
    //         readOk.classList.add(styles.iconShow)
    // }

    // function hideOkById(index) {deleteUserAddress
    //     const readOk = document.querySelector(`[data-ok~="${index * myId}"]`)
    //     readOk.classList.remove(styles.iconShow)
    // }

    function checkPhone(event, index) {
        const readOk = document.querySelector(`[data-ok~="${index * myId}"]`)
        if (event.target.value) {
            if (validatePhone(event.target.value)) {
                if (!readOk.classList.contains(styles.iconShow))
                    readOk.classList.add(styles.iconShow)
                event.target.parentElement.classList.remove(styles.borderRed)
            }
            else
                readOk.classList.remove(styles.iconShow)
        } else
            readOk.classList.remove(styles.iconShow)
    }

    function validateData() {
        //check required
        let arrayTextError = []
        let valid = true;
        valid = checkCountry();
        if (valid === false) {
            arrayTextError.push(translationMix(['account', 'billAddress', 'error', '1']))
        }
        for (let i = 3; i < arrLength - 1; i++) {
            if (inputsRefs.current[i].current.required) {

                if (i === 13) {
                    if (!validatePhoneRef(inputsRefs.current[i])) {
                        valid = false;
                        if (!inputsRefs.current[i].current.parentElement.classList.contains(styles.borderRed))
                            inputsRefs.current[i].current.parentElement.classList.add(styles.borderRed)
                        arrayTextError.push(translationMix(['account', 'billAddress', 'error', '2']))
                    }
                }
                else if (!inputsRefs.current[i].current.value) {
                    valid = false;
                    if (!inputsRefs.current[i].current.parentElement.classList.contains(styles.borderRed))
                        inputsRefs.current[i].current.parentElement.classList.add(styles.borderRed)
                } else if (i !== 9) {
                    inputsRefs.current[i].current.parentElement.classList.remove(styles.borderRed)
                }
            }
        }
        errorRef.current.innerText = arrayTextError.join(", ")
        errorRef.current.classList.add(styles.red)
        const myTimeOut3 = setTimeout(() => {
            errorRef.current.classList.add(styles.opacityFull)
        }, 300)
        const myTimeOut1 = setTimeout(() => {
            if (errorRef.current) {
                errorRef.current.classList.remove(styles.red)
                errorRef.current.classList.remove(styles.opacityFull)
            }
        }, 3000)
        const myTimeOut2 = setTimeout(() => {
            if (errorRef.current)
                errorRef.current.innerText = ''
        }, 3300)
        setErrorPasswordTimeOut([myTimeOut1, myTimeOut2, myTimeOut3])
        return valid;
    }

    function checkCountry() {
        const readOk = document.querySelector(`[data-ok~="${107 * myId}"]`)
        for (let i = 0; i < countries.length; i++) {
            if (inputsRefs.current[9].current.value.toLowerCase() === countries[i].name.toLowerCase()) {
                if (!readOk.classList.contains(styles.iconShow))
                    readOk.classList.add(styles.iconShow)
                inputsRefs.current[9].current.parentElement.classList.remove(styles.borderRed)
                return true;
            }
            else if (i === countries.length - 1) {
                readOk.classList.remove(styles.iconShow)
                if (!inputsRefs.current[9].current.parentElement.classList.contains(styles.borderRed))
                    inputsRefs.current[9].current.parentElement.classList.add(styles.borderRed)
                return false;
            }
        }
    }

    function showHints(event) {
        let readValue = null;
        if (listInputRef.current)
            readValue = listInputRef.current.value.toLowerCase();
        // if (!readValue)
        //     listRef.current.classList.remove(styles.searchListOpen);
        // else {
        listRef.current.classList.add(styles.searchListOpen);
        listRef2.current.classList.add(styles.searchHintsOpen);
        // let counter = 0;
        listRef2.current.innerHTML = '';
        for (let i = 0; i < countries.length; i++) {
            if (readValue !== null && readValue.length > 0) {
                if (countries[i].name.toLowerCase().startsWith(readValue)) {
                    const element = document.createElement('span');
                    element.classList.add(styles.searchElement);
                    element.innerText = countries[i].name;

                    element.onclick = function () {
                        inputsRefs.current[9].current.value = countries[i].name;
                        hideLabel(event, 107);
                        checkCountry(event)
                        listRef.current.classList.remove(styles.searchListOpen);
                    }
                    listRef2.current.insertAdjacentElement('beforeend', element);
                }
            } else if (countries[i].name.toLowerCase()) {
                // counter++;
                const element = document.createElement('span');
                element.classList.add(styles.searchElement);
                element.innerText = countries[i].name;

                element.onclick = function () {
                    inputsRefs.current[9].current.value = countries[i].name;
                    hideLabel(event, 107);
                    checkCountry(event)
                    listRef.current.classList.remove(styles.searchListOpen);
                }
                listRef2.current.insertAdjacentElement('beforeend', element);
            }
            // if (counter > 10) {
            //     break;
            // }
        }
        // if (counter === 0) {
        //     listRef.current.classList.remove(styles.searchListOpen);
        // }
        // }
    }

    function showHintsSearch(event) {
        const readValue = event.target.value.toLowerCase();
        listRef.current.classList.add(styles.searchListOpen);
        listRef2.current.classList.add(styles.searchHintsOpen);
        let counter = 0;
        listRef2.current.innerHTML = '';
        for (let i = 0; i < countries.length; i++) {
            if (countries[i].name.toLowerCase().startsWith(readValue)) {
                counter++;
                const element = document.createElement('span');
                element.classList.add(styles.searchElement);
                element.innerText = countries[i].name;

                element.onclick = function () {
                    inputsRefs.current[9].current.value = countries[i].name;
                    hideLabel(event, 107);
                    checkCountry(event)
                    listRef.current.classList.remove(styles.searchListOpen);
                }
                listRef2.current.insertAdjacentElement('beforeend', element);
            }
            if (counter > 10) {
                break;
            }
        }
        // if (counter === 0) {
        //     listRef.current.classList.remove(styles.searchListOpen);
        // }

    }

    function hideList() {
        listRef.current.classList.remove(styles.searchListOpen);
        listRef2.current.classList.remove(styles.searchListOpen);
    }

    function validateFirstPage() {
        setIsLoading(true)

        setTimeout(() => {
            if (validateData()) {
                if (save && !create) {
                    updateUserAddress(globalContext.authToken, userData.id, getAllData()).then(res => {
                        setIsLoading(false)
                        if (setRefresh)
                            setRefresh(true)
                        if (setOpened)
                            setOpened(false)
                        errorPasswordTimeOut.forEach(element => {
                            clearTimeout(element)
                        });
                        errorRef.current.innerText = translationMix(['account', 'billAddress', 'error', '0'])
                        errorRef.current.classList.add(styles.red)
                        const myTimeOut3 = setTimeout(() => {
                            errorRef.current.classList.add(styles.opacityFull)
                        }, 300)
                        const myTimeOut1 = setTimeout(() => {
                            if (errorRef.current) {
                                errorRef.current.classList.remove(styles.red)
                                errorRef.current.classList.remove(styles.opacityFull)
                            }
                        }, 3000)
                        const myTimeOut2 = setTimeout(() => {
                            if (errorRef.current)
                                errorRef.current.innerText = ''
                        }, 3300)
                        setErrorPasswordTimeOut([myTimeOut1, myTimeOut2, myTimeOut3])
                    })
                } else if (save && create) {
                    createUserAddress(globalContext.authToken, getAllData()).then(res => {
                        if (res.status) {
                            if (res.status === true) {
                                if (setRefresh)
                                    setRefresh(true)
                                if (setOpened) {
                                    setOpened(false)
                                }
                                window.location.reload()
                            }
                        }
                        setIsLoading(false)
                    })
                } else {
                    setIsLoading(false)
                    setUserData(getAllData())
                    setOpened(false)
                }
                return true;
            } else {
                setIsLoading(false)
                return false;
            }
        }, 500)
    }

    function cancle() {
        if (setAdressBillSelectedId) {
            setAdressBillSelectedId(null)
        }
        if (setUserData) {
            setUserData(null)
        }
        if (setCreate) {
            setCreate(false)
        }
        if (setOpened) {
            setOpened(false)
        }
    }

    function deleteAdress(id) {
        if (globalContext.authToken) {
            if (window.confirm(translationMix(['account', 'billAddress', 'info', '0']))) {
                deleteUserAddress(globalContext.authToken, id).then(json => {
                    if (json === true) {
                        window.location.reload()
                    }
                })
            }
        }
    }

    return (<>
        <div className={styles.container}>
            <div className={styles.mainContainer}>
                <div className={styles.main}>
                    <form autoComplete={"off"} noValidate onSubmit={(event) => { event.preventDefault(); validateFirstPage() }} className={styles.vertical}>
                        {create ? null : save ? userData ? null : <div className={styles.cover} ref={coverRef}></div> : null}
                        <div className={styles.title}>{create ? translationMix(['account', 'billAddress', 'title', '0']) : translationMix(['account', 'billAddress', 'title', '1'])}</div>
                        <div className={`${styles.error}`} ref={errorRef}></div>
                        <div className={styles.horizontalMain} style={{ marginBottom: "20px" }}>
                            <div className={styles.verticalRight}>
                                <div className={styles.horizontal}>
                                    <div className={styles.vertical}>
                                        <div className={styles.inputContainer}>
                                            <input ref={inputsRefs.current[6]} className={styles.input} type={"text"} required onInput={(event) => { hideLabel(event, 104); showHideOkReq(event, 104); }} />
                                            <div className={styles.labelContainer}>
                                                <div className={styles.textReq} data-label={104 * myId}>{translationMix(['account', 'create', 'lables', '4'])}</div>
                                            </div>
                                            <div className={styles.iconOk} data-ok={104 * myId} />
                                        </div>
                                        <div className={styles.inputContainer}>
                                            <input ref={inputsRefs.current[7]} className={styles.input} type={"text"} required onInput={(event) => { hideLabel(event, 105); showHideOkReq(event, 105); }} />
                                            <div className={styles.labelContainer}>
                                                <div className={styles.textReq} data-label={105 * myId}>{translationMix(['account', 'create', 'lables', '5'])}</div>
                                            </div>
                                            <div className={styles.iconOk} data-ok={105 * myId} />
                                        </div>
                                        <div className={styles.inputContainer}>
                                            <input ref={inputsRefs.current[8]} className={styles.input} type={"text"} required onInput={(event) => { hideLabel(event, 106); showHideOkReq(event, 106); }} />
                                            <div className={styles.labelContainer}>
                                                <div className={styles.textReq} data-label={106 * myId}>{translationMix(['account', 'create', 'lables', '6'])}</div>
                                            </div>
                                            <div className={styles.iconOk} data-ok={106 * myId} />
                                        </div>
                                        <div className={styles.inputContainer}>
                                            <input ref={inputsRefs.current[9]} className={styles.input} readOnly="readonly" type={"text"} required onClick={(event) => { hideLabel(event, 107); checkCountry(); showHints(event); listInputRef.current.focus(); }} />
                                            <div className={styles.labelContainer}>
                                                <div className={styles.textReq} data-label={107 * myId}>{translationMix(['account', 'create', 'lables', '7'])}</div>
                                            </div>
                                            <div className={styles.iconOk} data-ok={107 * myId} />
                                            <div ref={listRef} className={styles.searchListContainer} onBlur={() => { hideList() }}>
                                                <div className={styles.inputContainer2}>
                                                    <input ref={listInputRef} className={styles.input2} type={"text"} onInput={(event) => { showHintsSearch(event) }}></input>
                                                    <div className={styles.btnClose} onClick={() => { hideList() }} />
                                                </div>
                                                <div className={styles.searchList} ref={listRef2}>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.inputContainer}>
                                            <input ref={inputsRefs.current[5]} className={styles.input} type={"text"} required onInput={(event) => { hideLabel(event, 103); showHideOkReq(event, 103); }} />
                                            <div className={styles.labelContainer}>
                                                <div className={styles.textReq} data-label={103 * myId}>{translationMix(['account', 'create', 'lables', '12'])}</div>
                                            </div>
                                            <div className={styles.iconOk} data-ok={103 * myId} />
                                        </div>
                                    </div>
                                    <div className={styles.vertical}>
                                        <div className={styles.inputContainer}>
                                            <input ref={inputsRefs.current[10]} className={styles.input} type={"text"} required onInput={(event) => { hideLabel(event, 108); showHideOkReq(event, 108); }} />
                                            <div className={styles.labelContainer}>
                                                <div className={styles.textReq} data-label={108 * myId}>{translationMix(['account', 'create', 'lables', '8'])}</div>
                                            </div>
                                            <div className={styles.iconOk} data-ok={108 * myId} />
                                        </div>
                                        <div className={styles.inputContainer}>
                                            <input ref={inputsRefs.current[11]} className={styles.input} type={"text"} required onInput={(event) => { hideLabel(event, 109); showHideOkReq(event, 109); }} />
                                            <div className={styles.labelContainer}>
                                                <div className={styles.textReq} data-label={109 * myId}>{translationMix(['account', 'create', 'lables', '9'])}</div>
                                            </div>
                                            <div className={styles.iconOk} data-ok={109 * myId} />
                                        </div>
                                        <div className={styles.inputContainer}>
                                            <input ref={inputsRefs.current[12]} className={styles.input} type={"Miasto"} required onInput={(event) => { hideLabel(event, 110); showHideOkReq(event, 110); }} />
                                            <div className={styles.labelContainer}>
                                                <div className={styles.textReq} data-label={110 * myId}>{translationMix(['account', 'create', 'lables', '10'])}</div>
                                            </div>
                                            <div className={styles.iconOk} data-ok={110 * myId} />
                                        </div>
                                        <div className={styles.inputContainer}>
                                            <input ref={inputsRefs.current[3]} className={styles.input} type={"text"} required onInput={(event) => { hideLabel(event, 101); showHideOkReq(event, 101); }} />
                                            <div className={styles.labelContainer}>
                                                <div className={styles.textReq} data-label={101 * myId}>{translationMix(['account', 'create', 'lables', '1'])}</div>
                                            </div>
                                            <div className={styles.iconOk} data-ok={101 * myId} />
                                        </div>
                                        <div className={styles.inputContainer}>
                                            <input ref={inputsRefs.current[4]} className={styles.input} type={"text"} required onInput={(event) => { hideLabel(event, 102); showHideOkReq(event, 102); }} />
                                            <div className={styles.labelContainer}>
                                                <div className={styles.textReq} data-label={102 * myId}>{translationMix(['account', 'create', 'lables', '2'])}</div>
                                            </div>
                                            <div className={styles.iconOk} data-ok={102 * myId} />
                                        </div>
                                        <div className={styles.inputContainer}>
                                            <input ref={inputsRefs.current[13]} className={styles.input} type={"tel"} required onInput={(event) => { hideLabel(event, 111); checkPhone(event, 111); }} />
                                            <div className={styles.labelContainer}>
                                                <div className={styles.textReq} data-label={111 * myId}>{translationMix(['account', 'create', 'lables', '3'])}</div>
                                            </div>
                                            <div className={styles.iconOk} data-ok={111 * myId} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {save ? create ? <button type={"submit"} className={`${styles.btn2} ${styles.right}`}> {isLoading ? <LoaderAnimation width={20} height={20} isOne={true} /> : translationMix(['account', 'billAddress', 'btn', '0'])}</button> :
                            userData ? <button type={"submit"} className={`${styles.btn} ${styles.left}`}> {isLoading ? <LoaderAnimation width={20} height={20} isOne={true} /> : translationMix(['account', 'billAddress', 'btn', '0'])}</button> : null :
                            userData ? <button type={"submit"} className={`${styles.btn} ${styles.right}`}> {isLoading ? <LoaderAnimation width={20} height={20} isOne={true} /> : translationMix(['account', 'billAddress', 'btn', '0'])}</button> : null}
                        {create ? <div className={`${styles.btn2} ${styles.left}`} onClick={() => { cancle() }}>{translationMix(['account', 'billAddress', 'btn', '1'])}</div> : null}
                        {save ? userData ? !isCart ?
                            <div className={`${styles.btnDelete} ${styles.right}`} onClick={() => { deleteAdress(userData.id) }}>
                                {translationMix(['account', 'billAddress', 'btn', '2'])}
                            </div>
                            : null : null : null}
                    </form>
                </div>
            </div>
        </div>
    </>
    )

}

export default BillAddress;