const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
export function validateMailRef(target) {
    let value = target.current.value
    value = value.toString().trim()
    if (re.test(value))
        return true
    else
        return false
}

export function validateMailEvent(event) {
    let value = event.target.value
    value = value.toString().trim()
    if (re.test(value))
        return true
    else
        return false
}

export function validateMailValue(readValue) {
    let value = readValue
    value = value.toString().trim()
    if (re.test(value))
        return true
    else
        return false
}