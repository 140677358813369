import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { activateAccount } from "../../../apiCalls/authApiCalls";
import { translationMix } from "../../../Components/Helpers/translationMix";
import styles from "./activate.module.scss"

function Activate() {
    const [isDone, setIsDone] = useState(null);
    const { lang } = useParams()

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let readURLToken = urlParams.get('token');
        activateAccount(readURLToken).then(res => {
            if (res.status) {
                if (res.status === true) {
                    setIsDone(true)
                } else {
                    setIsDone(false)
                }
            } else {
                setIsDone(false)
            }
        })
    }, [])

    return (<>
        <Helmet>
            <title>{lang === 'pl' ? 'Bizserver - Aktywacja konta' : lang === 'en' ? 'Bizserver - Account activation' : 'Bizserver - Account Aktivierung'}</title>
            <meta name="description" content={"Bizserver - Aktywacja konta"} />
        </Helmet>
        {isDone === null ? <p className={styles.text}>{translationMix(['account', 'activate', 'loading'])}</p> :
            isDone === true ? <p className={styles.text}>{translationMix(['account', 'activate', 'textOk'])}</p> :
                <p className={styles.text}>{translationMix(['account', 'activate', 'textBad'])}</p>
        }
    </>
    )
}

export default Activate;