import React, { useRef } from "react";
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/core';
import styles from './styles/slider.module.scss'

function CategorySlider(props) {

    const splide = useRef()

    const startAutoplay = () => {
        //splide.current.splide.Components.Autoplay.play()
    }

    const stopAutoplay = () => {
        splide.current.splide.Components.Autoplay.pause()
    }

    return (
        <Splide hasTrack={false} aria-label="..." className={styles.carouselSmall} ref={splide} onMouseEnter={stopAutoplay} onMouseLeave={startAutoplay}
            onMove={(slider, newIndex) => { props.setActiveId(newIndex) }}
            options={{
                type: 'slide',
                gap: '25px',
                perPage: 4,
                perMove: 1,
                autoplay: false,
                interval: 3500,
                pagination: false,
                drag: true,
                speed: 500,
                rewind: true,
                arrows: false,
                breakpoints: {
                    1000: {
                        perPage: 3,
                        arrows: true
                    },
                    800: {
                        perPage: 2,
                    },
                    500: {
                        perPage: 1,
                    }
                }
            }}
        >
            <div className="splide__arrows">
                <button className={`splide__arrow splide__arrow--prev ${styles.arrow} ${styles.leftArrowThum}`} />
                <button className={`splide__arrow splide__arrow--next ${styles.arrow} ${styles.rightArrowThum}`} />
            </div>
            <SplideTrack className={styles.t}>
                {props.children?.map((item, index) => {
                    return (
                        <SplideSlide key={index}>
                            {item}
                        </SplideSlide>
                    )
                })}
            </SplideTrack>
        </Splide>
    )
}

export default CategorySlider