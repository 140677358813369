import styles from './medium.module.scss'
import ConvertDate from '../../Helpers/convertDate';
import { useParams } from 'react-router-dom';
import stringToUrl from '../../Helpers/stringToUrl';
import { translationMix } from '../../Helpers/translationMix';

function Medium({ data, isTextUnder = false }) {
    const { lang } = useParams();
    return (
        <div className={`${styles.container} ${isTextUnder ? styles.containerBig : ''}`}>
            <div className={`${isTextUnder ? styles.imgSmall : styles.imgBig}`} style={{ backgroundImage: `url("${data.image_url}")` }}><div className={`${isTextUnder ? styles.shadowLight : styles.shadow}`} /></div>
            <div className={`${styles.content} ${isTextUnder ? styles.contentUnder : ''}`}>
                <div className={styles.tag}>{data.tags ? data.tags.join(", ") : null}</div>
                {isTextUnder ? null :
                    <div className={styles.date}>{ConvertDate(data.updated_at, lang)}</div>
                }
                <div className={`${isTextUnder ? styles.title : styles.titleWhite}`}>{data.title}</div>
                <a href={`/${lang}/post/${data.id}-${stringToUrl(data.title)}`} className={`${styles.btnGo} ${isTextUnder ? styles.bottom : ''}`}>{translationMix(['shared', 'button', 'read'])}</a>
            </div>
        </div>
    )
}

export default Medium