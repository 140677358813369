import styles from './bigWide.module.scss'

function BigWide({ title, text, img, isUnder = false, index = -1 }) {
    return (
        <div className={`${styles.container} ${index === 0 ? '' : styles.topLine}`}>
            <div className={styles.textContainer}>
                <div className={styles.title} dangerouslySetInnerHTML={{ __html: title }}></div>
                <div className={styles.description} dangerouslySetInnerHTML={{ __html: text }}></div>
            </div>
            <div className={styles.img} style={{ backgroundImage: `url("${img}")` }}> 
            {/* data.file_name */}
            <div className={styles.shadow} />
            </div>
            <div className={styles.textContainer}>
                
                <div className={styles.moreSpace}/>
            </div>
        </div>
    )
}

export default BigWide