import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/core';

import styles from './sliderItem.module.scss'

function SliderItem(props) {

    return (
        <Splide hasTrack={false} aria-label="..." className={styles.carousel}
            options={{
                type: 'slide',
                perPage: 5,
                perMove: 1,
                gap: '20px',
                pagination: false, //te kropki na dole
                breakpoints: {
                    1660: {
                        perPage: 4
                    },
                    1350: {
                        perPage: 3
                    },
                    950: {
                        perPage: 2
                    },
                    650: {
                        perPage: 1
                    }
                }
            }}
        >
            <div className="splide__arrows">
                <button className={`splide__arrow splide__arrow--prev ${styles.arrow} ${styles.leftArrowThum}`} />
                <button className={`splide__arrow splide__arrow--next ${styles.arrow} ${styles.rightArrowThum}`} />
            </div>
            <SplideTrack className={styles.track}>
                {props.children.map((item, index) => {
                    return (
                        <SplideSlide id={index} key={index} className={styles.item} >
                            {item}
                        </SplideSlide>
                    );
                })}
            </SplideTrack>
        </Splide>
    )
}

export default SliderItem