import React, { useEffect, useState } from "react";
import { getAllProductCart } from "../../apiCalls/productApiCalls";
import Footer from "../Footer/footer";
import Header from "../Header/header";
import ItemList from "./itemList";

function ItemListContainer({ myMenuId, isSearch = false }) {

    const [cartDataContainer, setCartDataContainer] = useState(null)

    useEffect(() => {
        getAllProductCart().then(res => {
            if (res.status) {
                if (res.status === true) {
                    setCartDataContainer(res.cart)
                }
            }
        })
    }, [])

    return (
        <>
            <Header cartData={cartDataContainer} setCartData={setCartDataContainer} />
            <ItemList myMenuId={myMenuId} isSearch={isSearch} setCartData={setCartDataContainer} />
            <Footer />
        </>
    )
}

export default ItemListContainer;