import React from "react";
import { Link, useParams } from "react-router-dom";
import stringToUrl from "../Helpers/stringToUrl";
import { translationMix } from "../Helpers/translationMix";
import styles from './menuLeftMobile.module.scss'


function MenuLeftMobile({ menuData, menuId }) {
    const { lang } = useParams();
    const slideLeftRightHandler = (event, dataSet) => {
        const subMenu = document.querySelector(`[data-filtercontainer~="${0}"]`)

        if (subMenu.classList.contains(styles.menuOpen)) {
            subMenu.classList.remove(styles.menuOpen);
            subMenu.classList.add(styles.menuClose);
            subMenu.classList.add(styles.filterTransition);
            event.target.classList.remove(styles.buttonOpen)
            event.target.classList.add(styles.buttonClose)
            event.target.classList.add(styles.filterTransition);
            subMenu.addEventListener('transitionend', () => {
                subMenu.classList.remove(styles.filterTransition);
                event.target.classList.remove(styles.filterTransition);
            }, { once: true });
        }
        else {
            subMenu.classList.remove(styles.menuClose);
            subMenu.classList.add(styles.menuOpen);
            subMenu.classList.add(styles.filterTransition);
            event.target.classList.remove(styles.buttonClose)
            event.target.classList.add(styles.buttonOpen)
            event.target.classList.add(styles.filterTransition);
            subMenu.addEventListener('transitionend', () => {
                subMenu.classList.remove(styles.filterTransition);
                event.target.classList.remove(styles.filterTransition);
            }, { once: true });
        }
    }
    
    return (
        <div className={styles.menuContainer} data-menucontainer={0}>
            <div className={`${styles.filterMenuContainer} ${styles.menuClose}`} data-filtercontainer={0}>
                <div className={styles.filterMenu} >
                    <div className={`${styles.menu} ${styles.menuMobile}`}>
                        <div className={styles.menuTitle}>{translationMix(['header','menu','info','0'])}</div>
                        {menuData ?
                            menuData.map((item, index) => {
                                return (
                                    <div className={styles.point} key={index}>
                                        <Link key={index} to={`/${lang}/${stringToUrl(item[1])}`}><div className={`${styles.myLink} ${index === menuId ? styles.titleOpen : ""}`}>{item[0]}</div></Link>
                                    </div>
                                )
                            })
                            : null}
                    </div>
                </div>
                <div className={`${styles.filterButton} `} onClick={(event) => { slideLeftRightHandler(event, 0) }} />
            </div>
        </div>
    )
}

export default MenuLeftMobile