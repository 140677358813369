import React, { useEffect, useRef, useState } from 'react';
import styles from './loader.module.scss'

export function Loader(props) {
    const [isAnimation, setIsAnimation] = useState(true)
    const [isScroll, setIsScroll] = useState(props.scroll)
    const loaderRef = useRef()

    useEffect(() => {
        if ((props.data && document.getElementById("loader")) && !props.justLoad) {
            loaderRef.current.style.transition = "0.4s all"
            if (props.scroll !== false || isScroll) {
                window.scrollTo(0, 0)
                setIsScroll(false)
            }
            setTimeout(() => {
                if (loaderRef.current) {
                    loaderRef.current.style.opacity = "0"
                    loaderRef.current.style.visibility = "hidden"
                }
                setIsAnimation(false);
            }, 100)
        } else if (props.justLoad) {
            if (loaderRef.current)
                loaderRef.current.style.transition = "0.4s all"
            if (props.scroll !== false || isScroll) {
                window.scrollTo(0, 0)
                setIsScroll(false)
            }
            setTimeout(() => {
                if (loaderRef.current) {
                loaderRef.current.style.opacity = "0"
                loaderRef.current.style.visibility = "hidden"
                }
                setIsAnimation(false);
            }, 700)
        } else {
            loaderRef.current.style.transition = "0s all"
            loaderRef.current.style.opacity = "1"
            loaderRef.current.style.visibility = "visable"
        }
    }, [props.data, props.scroll, isScroll, props.justLoad])

    return (
        <div className={styles.loader} ref={loaderRef} id={"loader"}>
            {isAnimation ?
                <div className={styles.loaderInner}>
                    <div className={styles.globe}></div>
                    <div className={styles.one}></div>
                    <div className={styles.two}></div>
                </div> : null}
        </div>
    );
}