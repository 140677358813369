import React from "react";
import { Link, useParams } from "react-router-dom";
import styles from './product.module.scss'
import numberWithSpace from "../../../Components/Helpers/numberWithSpace";
import { addProductCart } from "../../../apiCalls/productApiCalls";
import { useRef } from "react";
import stringToUrl from "../../Helpers/stringToUrl";
import { translationMix } from "../../Helpers/translationMix";
import noImageSmall from '../../../assets/img/Shared/noImageSmall.jpg'

function Product({ data, myId, choosenOpt, setChoosenOption, horizontal = false, preview = false, setCartData }) {
    const { lang } = useParams();
    const productRef = useRef();
    const inforRef = useRef();

    // const changeButton = (event) => {
    //     const allCompareButtons = document.querySelectorAll(`[data-comparebutton~="${0}"]`)
    //     let tmpLenght = choosenOpt.length;
    //     if (event.target.checked) {
    //         tmpLenght++;
    //         setChoosenOption([...choosenOpt, event.target.id]);
    //     } else {
    //         tmpLenght--;
    //         const index = choosenOpt.indexOf(event.target.id);
    //         if (index > -1) {
    //             choosenOpt.splice(index, 1);
    //             setChoosenOption([...choosenOpt])
    //         }
    //     }
    //     Array.from(allCompareButtons).map((x) => { return (x.textContent = translation.itemList.button.compare + "(" + (tmpLenght) + ")") })
    // }

    function addToCart() {
        addProductCart(myId, 1).then(json => {
            let iText = translationMix(['shared', 'addToCart'])
            if (!json.status) {
                iText = translationMix(['shared', 'addToCartError'])
            } else{
                setCartData(json.cart)
            }
            inforRef.current.innerText = iText
            if (!productRef.current.classList.contains(styles.productSlelected)) {
                productRef.current.classList.add(styles.productActive)
            }
            if (!inforRef.current.classList.contains(styles.cartInfoOpacity)) {
                inforRef.current.classList.add(styles.cartInfoOpacity)
            }
            setTimeout(() => {
                if (inforRef.current) {
                    inforRef.current.classList.remove(styles.cartInfoOpacity)
                }
                if (productRef.current) {
                    productRef.current.classList.remove(styles.productActive)
                }
            }, 3000)
        })
    }

    return (
        <div ref={productRef} className={`${preview ? styles.productItem : horizontal ? styles.productHorizontal : styles.product} ${choosenOpt.includes(String(myId)) ? styles.productActive : ''}`} key={myId} data-product={myId}>
            <div className={`${horizontal ? styles.productTopHorizontal : styles.productTop}`}>
                {data.discount > 0 ? <div className={styles.saleButton}>{translationMix(['shared', 'button', 'salary'])}</div> : null}
                {/* <div className={styles.right}>
                    <label className={styles.checkbox}>{translation.shared.button.compare}
                        <input type="checkbox" id={myId} data-product-checkbox={myId} onClick={(event) => { changeButton(event) }} defaultChecked={`${choosenOpt.includes(String(myId)) ? "checked" : ''}`} />
                        <span className={styles.checkmark}></span>
                    </label>
                </div> */}
            </div>
            <div className={`${horizontal ? styles.imageContainerHorizontal : styles.imageContainer}`}>
                <div className={`${horizontal ? styles.imageHorizontal : styles.image}`} style={{ backgroundImage: `url("${data.image ? data.image : noImageSmall}")` }} />
                <div className={`${horizontal ? styles.logoHorizontal : styles.logo}`} style={{ backgroundImage: `url("${data.manufacturer?.image}")` }} />
                <Link to={`/${lang}/product/${data.id}-${stringToUrl(data.name)}`} className={`${horizontal ? styles.btnToProductHorizontal : styles.btnToProduct}`} aria-label="Product"> </Link>
            </div>
            <div className={`${horizontal ? styles.descriptionHorizontal : styles.description}`}>
                <div className={`${horizontal ? styles.productNameHorizontal : styles.productName}`}>{data.name}</div>
                {/* <div className={`${horizontal ? styles.productNameHorizontal : styles.productName}`}>Kod Producenta</div> */}
                {data.attributes?.slice(0, 4).map((item, index) => {
                    return [
                        <div key={index} className={styles.descriptionContainer}>
                            <div key={index} className={styles.text1}>{item.name}:</div>
                            <div className={styles.text2}>{item.value}</div></div>
                    ]
                })}
            </div>
            <div className={`${horizontal ? styles.priceHorizontal : styles.price}`}>
                <div className={styles.cost}>
                    {data.discount ? <div className={styles.priceOld}>{numberWithSpace(data.price_original)}{data.currency} {translationMix(['shared', 'price', 'net'])}</div> : null}
                    <div className={`${data.discount ? styles.priceNew : styles.priceNormalNet}`}>{numberWithSpace(data.price)}{data.currency} {translationMix(['shared', 'price', 'net'])}</div>
                    <div className={styles.priceNormalGross}>{numberWithSpace(data.price_gross)}{data.currency} {translationMix(['shared', 'price', 'gorss'])}</div>
                    {data.quantity <= 0 ? <div className={styles.amountNone}>{translationMix(['shared', 'amount', '2'])}</div> :
                        data.quantity < 50 ? <div className={styles.amountSmall}>{translationMix(['shared', 'amount', '1'])}</div> :
                            <div className={styles.amountBig}>{translationMix(['shared', 'amount', '0'])}</div>}
                </div>
                <div className={styles.cartButton} onClick={() => { addToCart() }}></div>
            </div>
            <div ref={inforRef} className={styles.cartInfo}></div>
        </div>
    )
}

export default Product