import { apiUrls } from "../assets/api/apiUrls";

export async function getBlogMainPage() {
    const url = `${apiUrls.mainUrl}${apiUrls.blog}`
    const options = {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include'
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return end;
}

export async function searchBlog(text) {
    const url = `${apiUrls.mainUrl}${apiUrls.seachPost}?searchText=${text}`
    const options = {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include'
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return end;
}

export async function getPost(id) {
    const url = `${apiUrls.mainUrl}${apiUrls.post[0]}${id}${apiUrls.post[1]}`
    const options = {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include'
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return end;
}