import React, { useState, createContext } from 'react';
import { refreshToken } from '../../apiCalls/authApiCalls';
import { apiUrls } from '../../assets/api/apiUrls';


export const ThemeContext = createContext({
    cartData: [],
    allLang: [],
    allLangApi: [],
    allCurrecny: [],
    menuData: null,
    authToken: null,
    itemListFirstLoad: null
});


const ThemeContextProvider = props => {
    const [cartData, setCartData] = useState(null)
    const [allLang, setAllLang] = useState(null)
    const [allLangApi, setAllLangApi] = useState(null)
    const [allCurrecny, setAllCurrecny] = useState(null)
    const [menuData, setMenuData] = useState(null)
    const [authToken, setAuthToken] = useState(null)
    const [itemListFirstLoad, setItemListFirstLoad] = useState(true)

    const updateItemListFirstLoad = (value) => {
        setItemListFirstLoad(value)
    }
    
    const updateMenuData = (item) => {
        setMenuData(item)
    }

    const updateLang = (item) => {
        setAllLang(item)
    }

    const updateLangApi = (item) => {
        setAllLangApi(item)
    }

    const updateCurrency = (item) => {
        setAllCurrecny(item)
    }

    const updateCart = (item) => {
        setCartData(item)
    }

    const updateAuthToken = (item) => {
        setAuthToken(item)
    }

    const getToken = () => {
        if (authToken) {
            return authToken
        } else {
            refreshToken().then(res => {
                if (res.status) {
                    setAuthToken(res.token)
                    return res.token
                } else
                    return null
            })
        }
        return null
    }

    async function checkToken() {
            const url = `${apiUrls.mainUrl}auth/refresh-token`
            const options = {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'include'
            }
            const response = await fetch(url, options)
            const end = await response.json();
            if (end.statusCode !== 401) {
                setAuthToken(end.token)
                return end.token;
            } else {
                setAuthToken(null)
                return null;
            }
    }

    return (
        <ThemeContext.Provider value={{
            cartData: cartData,
            menuData: menuData,
            allCurrecny: allCurrecny,
            allLang: allLang,
            allLangApi: allLangApi,
            authToken: authToken,
            itemListFirstLoad: itemListFirstLoad,
            updateItemListFirstLoad,
            updateMenuData,
            updateLang,
            updateLangApi,
            updateCurrency,
            updateCart,
            updateAuthToken,
            getToken,
            checkToken
        }}>
            {props.children}
        </ThemeContext.Provider>
    )
}


export default ThemeContextProvider;