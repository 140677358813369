import React, { useContext, useEffect, useState } from "react";
import { Loader } from "../../../Components/Loader/loader";
import styles from './userOrderDetails.module.scss'
import { translationMix } from "../../../Components/Helpers/translationMix";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import LoaderAnimation from "../../../Components/LoaderAnimation/loaderAnimation";
import stringToUrl from "../../../Components/Helpers/stringToUrl";
import numberWithSpace from "../../../Components/Helpers/numberWithSpace";
import { getUserOrderById } from "../../../apiCalls/productApiCalls";
import { ThemeContext } from "../../../Components/Context/context";
import { Helmet } from "react-helmet";

function UserOrderDetails() {
    const [isLoading, setIsLoading] = useState(false);
    const [oneOrder, setOneOrder] = useState(false);
    const { lang, id } = useParams();
    const globalContext = useContext(ThemeContext)

    function sendData() {
        setIsLoading(true)

        setTimeout(() => {
            setIsLoading(false)
        }, 500)

        // if (globalContext.authToken) {
        //     const data = { deliveryAddress: userSendAddress, billAddress: userBillAddress, comment: userComment }
        //     sendUserDataCart(globalContext.authToken, data).then(res => {
        //         setIsLoading(false)
        //         if (res) {
        //             if (res.status) {
        //                 if (res.status === true) {
        //                     setIsUserFinish(true)
        //                 } else
        //                     setIsUserFinish(false)
        //             } else
        //                 setIsUserFinish(false)
        //         } else
        //             setIsUserFinish(false)
        //     })
        // }

        // if (userData) {
        //     // const userDataJson = {
        //     //     email: userData.email,
        //     //     gender: userData.gender,
        //     //     firstname: userData.firstname,
        //     //     lastname: userData.lastname,
        //     //     phone: userData.phone,
        //     // }

        //     const userDeliveryAddressJson = {
        //         firstname: userData.firstname,
        //         lastname: userData.lastname,
        //         company_name: userData.company_name,
        //         street_number: userData.street_number,
        //         zip_code: userData.zip_code,
        //         id_country: userData.id_country,
        //         country_name: userData.country_name,
        //         street: userData.street,
        //         apartament_number: userData.apartament_number,
        //         city: userData.city,
        //         nip: userData.nip ? userData.nip : "",
        //         phone: userData.phone
        //     }

        //     const userBillAddressJson = {
        //         firstname: userBillAddress.firstname,
        //         lastname: userBillAddress.lastname,
        //         company_name: userBillAddress.company_name,
        //         street_number: userBillAddress.street_number,
        //         zip_code: userBillAddress.zip_code,
        //         id_country: userBillAddress.id_country ? userBillAddress.id_country : null,
        //         country_name: userBillAddress.country_name,
        //         street: userBillAddress.street,
        //         apartament_number: userBillAddress.apartament_number,
        //         city: userBillAddress.city,
        //         nip: userBillAddress.nip ? userBillAddress.nip : "",
        //         phone: userData.phone
        //     }
        //     const data = { userData: userData, deliveryAddress: userDeliveryAddressJson, billAddress: userBillAddressJson, comment: userComment }

        //     sendNoAuthenticatedUserDataCart(data).then(res => {
        //         setIsLoading(false)
        //         if (res) {
        //             if (res.status) {
        //                 if (res.status === true) {
        //                     setIsUserFinish(true)
        //                 } else
        //                     setIsUserFinish(false)
        //             } else
        //                 setIsUserFinish(false)
        //         } else
        //             setIsUserFinish(false)
        //     })
        // } else {

        // }
    }

    useEffect(() => {
        if (globalContext.authToken) {
            getUserOrderById(globalContext.authToken, id).then(json => {
                setOneOrder(json)
            })
        }
    }, [])

    return (
        <>
            <Helmet>
                <title>{lang === 'pl' ? 'Bizserver - Szczegóły Zamówienia' : lang === 'en' ? 'Bizserver - Order Details' : lang === 'de' ? 'Bizserver - Bestelldetails' : 'Bizserver - Podrobnosti objednávky'}</title>
                <meta name="description" content={lang === 'pl' ? "Bizserver - Tu znajdziesz szczegóły swojego zamówienia" : lang === 'en' ? 'Bizserver - Here you will find the details of your order' : lang === 'de' ? 'Bizserver - Hier finden Sie die Details Ihrer Bestellung' : 'Bizserver - Zde najdete podrobnosti o své objednávce'} />
            </Helmet>
            <Loader justLoad={true} />
            <div className={styles.container}>
                <div className={styles.mainContainer}>
                    <div className={styles.main}>
                        <p className={styles.textStrong}>{translationMix(['cart', 'summary', 'title'])}</p>
                        <div className={styles.top}>
                            <div className={styles.left}>
                                <p className={styles.textStrong}>{translationMix(['cart', 'summary', 'labels', '2'])}</p>
                                <div className={styles.adress}>
                                    <div className={styles.column}>
                                        {oneOrder.address ?
                                            <p className={styles.textSmall}>
                                                {oneOrder.address.address_firstname} {oneOrder.address.address_lastname} < br />
                                                {oneOrder.address.address_company_name}<br />
                                                {oneOrder.address.address_street} {oneOrder.address.address_street_number}/{oneOrder.address.address_apartament_number}< br />
                                                {oneOrder.address.address_zip_code} {oneOrder.address.address_city}< br />
                                                {oneOrder.address.address_country}< br />
                                                {oneOrder.address.address_phone}<br />
                                            </p> : <p className={styles.textSmall}></p>}
                                    </div>
                                </div>
                                <p className={styles.textStrong}>{translationMix(['cart', 'summary', 'labels', '3'])}</p>
                                <div className={styles.adress}>
                                    <div className={styles.column}>
                                        {oneOrder.bill_address ?
                                            <p className={styles.textSmall}>
                                                {oneOrder.bill_address.bill_address_firstname} {oneOrder.bill_address.bill_address_lastname} < br />
                                                {oneOrder.bill_address.bill_address_company_name}<br />
                                                {oneOrder.bill_address.bill_address_street} {oneOrder.bill_address.bill_address_street_number}/{oneOrder.bill_address.bill_address_apartament_number}< br />
                                                {oneOrder.bill_address.bill_address_zip_code} {oneOrder.bill_address.bill_address_city}< br />
                                                {oneOrder.bill_address.bill_address_country}< br />
                                                {oneOrder.bill_address.bill_address_phone}</p> :
                                            <p className={styles.textSmall}></p>}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.right}>
                                <p className={styles.textSmall2}>{translationMix(['cart', 'summary', 'labels', '4'])}:</p>
                                <div className={styles.tableContainer}>
                                    {oneOrder.products ? oneOrder.products.products.list.map((readProduct, index) => {
                                        return (
                                            <div className={styles.item} key={index}>
                                                <Link to={`/${lang}/product/${readProduct.id}-${stringToUrl(readProduct.name)}`} className={styles.icon} style={{ backgroundImage: `url("${readProduct.image}")` }} />
                                                <div className={styles.descriptionContainer}>
                                                    <div className={styles.text}>{readProduct.name}</div>
                                                    <div className={styles.ultraSmallText}>SKU: {readProduct.reference}</div>
                                                </div>
                                                <div className={styles.totalPrice}>{numberWithSpace(readProduct.price_total)} {oneOrder.products?.products?.currency}</div>
                                            </div>
                                        )
                                    }) : null}
                                </div>
                                <div className={styles.summary}>
                                    <div className={styles.endText}>{translationMix(['cart', 'summary', 'summary', '0'])}</div>
                                    <div className={styles.textMedium}>{oneOrder.products?.products.price_products_total ? numberWithSpace(oneOrder.products?.products.price_products_total) : null} {oneOrder.products?.products?.currency ? oneOrder.products?.products?.currency : null}</div>
                                </div>
                                {/* <div className={styles.summary}>
                            <div className={styles.endText}>Wysyłka (zmień)</div>
                            <div className={styles.textMedium}>{totalPrice ? totalPrice.price_delivery : null} {currency ? currency : null}</div>
                        </div> */}
                                {/*<div className={styles.summary}>
                            <div className={styles.endText}>Płatność szybki przelew</div>
                            <div className={styles.textMedium}></div>
                        </div> */}
                                <div className={styles.total}>
                                    <p className={styles.textBig}>{translationMix(['cart', 'summary', 'summary', '1'])}:</p>
                                    <p className={styles.textBig}>{oneOrder.products?.products.price_products_total ? numberWithSpace(oneOrder.products?.products.price_products_total) : null} {oneOrder.products?.products?.currency ? oneOrder.products?.products?.currency : null}</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className={styles.containerBottom}>
                            <div className={styles.btnGreyDark} onClick={() => sendData()}>
                                {isLoading ? <LoaderAnimation width={20} height={20} isOne={true} /> : translationMix(['cart', 'summary', 'btn', '1'])}
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserOrderDetails;