import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { checkMailInDB, sendMailPasswordReset } from "../../../apiCalls/authApiCalls";
import { ThemeContext } from "../../../Components/Context/context";
import { validateMailEvent, validateMailRef } from "../../../Components/Helpers/mailValidation";
import { translationMix } from "../../../Components/Helpers/translationMix";
import { Loader } from "../../../Components/Loader/loader";
import styles from "./passwordResetMail.module.scss"

function PasswordResetMail() {
    const [isDone, setIsDone] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const mailErrorRef = useRef(null)
    const mailRef = useRef(null)
    const globalContextRef = useRef(useContext(ThemeContext))
    const { lang } = useParams()

    function hideLabel(event, index) {
        const readInput = document.querySelector(`[data-label~="${index}"]`)
        if (event.target.value) {
            if (!readInput.classList.contains(styles.hide))
                readInput.classList.add(styles.hide)
        } else {
            readInput.classList.remove(styles.hide)
        }
    }

    function showHideOkMail(event, index) {
        const readOk = document.querySelector(`[data-ok~="${index}"]`)
        if (validateMailEvent(event)) {
            if (!readOk.classList.contains(styles.iconShow))
                readOk.classList.add(styles.iconShow)
            event.target.parentElement.classList.remove(styles.borderRed)
        } else
            readOk.classList.remove(styles.iconShow)
    }

    function checkMailExist() {
        if (validateMailRef(mailRef)) {
            //check mail, wait, do something
            checkMailInDB(mailRef.current.value).then(check => {
                if (check) {
                    sendMailPasswordReset(mailRef.current.value).then(res => {
                        if (res.status) {
                            if (res.status === true) {
                                setIsDone(true);
                                return true;
                            }
                        }
                    });
                } else {
                    mailErrorRef.current.innerText = translationMix(['account', 'passwordReset', 'error', '0'])
                }
            })
        } else
            mailErrorRef.current.innerText = translationMix(['account', 'passwordReset', 'error', '1'])


        if (!mailErrorRef.current.classList.contains(styles.red))
            mailErrorRef.current.classList.add(styles.red)
        setTimeout(() => {
            if (!mailErrorRef.current.classList.contains(styles.opacityFull))
                mailErrorRef.current.classList.add(styles.opacityFull)
        }, 300)
        setTimeout(() => {
            if (mailErrorRef.current)
                mailErrorRef.current.classList.remove(styles.red)
        }, 3000)

        return false;
    }

    //Check is login then redirect do userData
    useEffect(() => {
        globalContextRef.current.checkToken().then(res => {
            if (res) {
                setIsAuthenticated(false)
                window.location.href = `/${lang}/account-user`
            } else {
                setIsAuthenticated(true)
            }
        })
    }, [lang])

    return (<>
        <Helmet>
            <title>{lang === 'pl' ? 'Bizserver - Zapomniałem hasła' : lang === 'en' ? 'Bizserver - I forgot my password' : 'Bizserver - Ich habe mein Passwort vergessen'}</title>
            <meta name="description" content={"Bizserver - Aktywacja konta"} />
        </Helmet>
        <Loader data={isAuthenticated} />
        {isDone === null ?
            <div className={`${styles.container}`}>
                <div className={styles.mainContainer}>
                    <div className={styles.main}>
                        <form autoComplete={"off"} noValidate onSubmit={(event) => { event.preventDefault(); checkMailExist(); }} className={`${styles.vertical}`}>
                            <div className={`${styles.horizontal} ${styles.center}`}>
                                <div className={`${styles.vertical} ${styles.myLimitWidht}`}>
                                    <div className={`${styles.error}`} ref={mailErrorRef}></div>
                                    <div className={styles.text1}>{translationMix(['account', 'passwordReset', 'title'])}</div>
                                    <div className={styles.inputContainer}>
                                        <input ref={mailRef} className={styles.input} type={"email"} required onInput={(event) => { hideLabel(event, 1); showHideOkMail(event, 1); }} />
                                        <div className={styles.labelContainer}>
                                            <div className={styles.textReq} data-label={1}>E-mail</div>
                                        </div>
                                        <div className={styles.iconOk} data-ok={1} />
                                    </div>
                                </div>
                            </div>
                            <button type={"submit"} className={`${styles.btn} ${styles.right}`}>{translationMix(['account', 'passwordReset', 'btn'])}</button>
                        </form>
                    </div>
                </div>
            </div> : isDone === true ?
                <p className={styles.endText}>{translationMix(['account', 'passwordReset', 'info1'])}</p> :
                <p className={styles.endText}>{translationMix(['account', 'passwordReset', 'info2'])}</p>
        }
    </>
    )

}

export default PasswordResetMail;