import React, { useEffect, useState } from "react";

import Header from "../Header/header";
import Item from "./item";
import Footer from "../Footer/footer";
import { getAllProductCart } from "../../apiCalls/productApiCalls";

function ItemContainer({ }) {
    const [cartDataContainer, setCartDataContainer] = useState(null)

    useEffect(() => {
        getAllProductCart().then(res => {
            setCartDataContainer(res.cart)
        })
    }, [])

    return (
        <>
            <Header cartData={cartDataContainer} />
            <Item setCartData={setCartDataContainer} />
            <Footer />
        </>
    )
}

export default ItemContainer;