import React from "react";
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/core';
import styles from './registerSlider.module.scss'

function RegisterSlider(props) {
    return (
        <Splide hasTrack={ false } aria-label="..." className={styles.carouselBig} ref={props.sliderRef}
                options={{
                    type: 'slide',
                    gap: '25px',
                    perPage: 1,
                    perMove: 1,
                    autoplay: false,
                    interval: 3500,
                    pagination: false,
                    drag: false,
                    speed: 500,
                    arrows: false
                }}
        >

            <SplideTrack>
                {props.children?.map((item, index) => {
                    return (
                        <SplideSlide key={index}>
                            {item}
                        </SplideSlide>
                    )
                })}
            </SplideTrack>
        </Splide>
    )
}

export default RegisterSlider