import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/core';

import styles from './categorySlider.module.scss'

function CategorySlider(props) {

    return (
        <Splide hasTrack={false} aria-label="..." className={styles.carousel}
            options={{
                type: 'slide',
                perPage: 7,
                perMove: 1,
                cover: true,
                drag: false,
                gap: '20px',
                pagination: false, //te kropki na dole
                //drag: false,
                breakpoints: {
                    1500: {
                        perPage: 6,
                        drag: true
                    },
                    1200: {
                        perPage: 7,
                        drag: false
                    },
                    950: {
                        perPage: 6,
                        drag: true
                    },
                    800: {
                        perPage: 5
                    },
                    700: {
                        perPage: 4
                    },
                    550: {
                        perPage: 3
                    },
                    450 : {
                        perPage: 2
                    }
                }
            }}
        >
            <div className="splide__arrows">
                <button className={`splide__arrow splide__arrow--prev ${styles.arrow} ${styles.leftArrowThum}`} />
                <button className={`splide__arrow splide__arrow--next ${styles.arrow} ${styles.rightArrowThum}`} />
            </div>
            <SplideTrack className={styles.s}>
                {props.children.map((item, index) => {
                    return (
                        <SplideSlide id={index} key={index}>
                            {item}
                        </SplideSlide>
                    );
                })}
            </SplideTrack>
        </Splide>
    )
}

export default CategorySlider