import { apiUrls } from "../assets/api/apiUrls";
import getLang from "../Components/Helpers/getLang";

export async function getGlobal() {
    const url = `${apiUrls.mainUrl}${apiUrls.global}?idFront=3`

    const options = {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include'
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return end;
}

export async function sendLanguageChange(apiKey) {
    const url = `${apiUrls.mainUrl}${apiUrls.global}?apiKey=${apiKey}`

    const options = {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include'
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return end;
}

export async function sendCurrencyChange(id) {
    const url = `${apiUrls.mainUrl}${apiUrls.global}?apiCurrency=${id}`

    const options = {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include'
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return end;
}

export async function getHomePage() {
    const url = `${apiUrls.mainUrl}${apiUrls.homepage}`

    const options = {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include'
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return end;
}

export async function getTextPage(id) {
    const url = `${apiUrls.mainUrl}/text-pages/get?id=${id}`

    const options = {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include'
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return end;
}

export async function getMainMenu() {
    const url = `${apiUrls.mainUrl}${apiUrls.menu}${getLang}`
    // const url = `${apiUrls.mainUrl}${apiUrls.menu}${getLang}`

    const options = {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include'
    }
    const response = await fetch(url, options)
    const end = await response.json();
    return end;
}