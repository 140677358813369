import styles from './category.module.scss'
import { useParams } from 'react-router-dom';
import { translationMix } from '../../Helpers/translationMix';

function Category({ data, id, setActiveId, isActive = false }) {
    const { lang } = useParams();
    return (
        <div className={`${styles.container} ${isActive ? styles.active : styles.noActive}`} onMouseEnter={() => setActiveId(id)}>
            <div className={styles.img} style={{ backgroundImage: `url("${data.image_url}")` }}>
                <div className={styles.shadow} />
            </div>
            <div className={styles.content}>
                <div className={styles.title}>{data.name}</div>
                <div className={styles.descriptionContainer}>
                    <div className={styles.description} dangerouslySetInnerHTML={{ __html: data.description }}></div>
                </div>
                {/* href={`/${lang}/post/${data.id}-${stringToUrl(data.title)}`} */}
                <a href={`/${lang}`} className={styles.btnGo}>{translationMix(['shared', 'button', 'read'])}</a>
            </div>
            <div className={`${styles.deactivate} ${isActive ? '' : styles.opacityFull}`} />
        </div>
    )
}

export default Category