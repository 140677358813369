import React, { createRef, useEffect, useRef, useState } from "react";
import { apiUrls } from "../../../assets/api/apiUrls";
import { translationMix } from "../../../Components/Helpers/translationMix";
import LoaderAnimation from "../../../Components/LoaderAnimation/loaderAnimation";
import styles from "./address.module.scss"

function Address({ userData, setUserData, setOpened }) {
    const [countries, setCountries] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const listInputRef = useRef(null)
    const listRef = useRef(null)
    const listRef2 = useRef(null)
    const inputsRefs = useRef([])
    
    const arrLength = 14
    if (inputsRefs.current.length !== arrLength) {
        // add or remove refs
        inputsRefs.current = Array(arrLength)
            .fill()
            .map((_, i) => inputsRefs.current[i] || createRef());
    }

    function getAllData() {
        if (validateData()) {
            let countryId = ''
            for (let i = 0; i < countries.length; i++) {
                if (countries[i].name.toLowerCase() === inputsRefs.current[9].current.value.toLowerCase()) {
                    countryId = countries[i].id
                    break
                }
            }
            const myJsonData = {
                email: userData.email,
                gender: userData.gender,
                firstname: userData.firstname,
                lastname: userData.lastname,
                phone: userData.phone,
                company_name: inputsRefs.current[6].current.value,
                street_number: inputsRefs.current[7].current.value,
                zip_code: inputsRefs.current[8].current.value,
                id_country: countryId,
                country_name: inputsRefs.current[9].current.value,
                street: inputsRefs.current[10].current.value,
                apartament_number: inputsRefs.current[11].current.value,
                city: inputsRefs.current[12].current.value,
            }
            return myJsonData
        }
        else
            return JSON.parse(null)
    }

    function hideLabel(event, index) {
        const readInput = document.querySelector(`[data-label~="${index}"]`)
        if (event.target.value) {
            if (!readInput.classList.contains(styles.hide))
                readInput.classList.add(styles.hide)
        } else {
            readInput.classList.remove(styles.hide)
        }
    }

    function hideLabelByIndex(index) {
        const readLabel = document.querySelector(`[data-label~="${index}"]`)
        if (!readLabel.classList.contains(styles.hide))
            readLabel.classList.add(styles.hide)
    }

    function showHideOkReq(event, index) {
        const readOk = document.querySelector(`[data-ok~="${index}"]`)
        if (event.target.value) {
            if (!readOk.classList.contains(styles.iconShow))
                readOk.classList.add(styles.iconShow)
            event.target.parentElement.classList.remove(styles.borderRed)
        } else
            readOk.classList.remove(styles.iconShow)
    }

    function showHideOkById(index) {
        const readOk = document.querySelector(`[data-ok~="${index}"]`)
        if (!readOk.classList.contains(styles.iconShow))
            readOk.classList.add(styles.iconShow)

    }

    function validateData() {
        //check required
        let valid = true;
        valid = checkCountry();
        for (let i = 6; i < arrLength - 1; i++) {
            if (inputsRefs.current[i].current.required) {
                if (!inputsRefs.current[i].current.value) {
                    valid = false;
                    if (!inputsRefs.current[i].current.parentElement.classList.contains(styles.borderRed))
                        inputsRefs.current[i].current.parentElement.classList.add(styles.borderRed)
                } else if (i !== 9) {
                    inputsRefs.current[i].current.parentElement.classList.remove(styles.borderRed)
                }
            }
        }
        return valid;
    }

    function checkCountry() {
        const readOk = document.querySelector(`[data-ok~="${7}"]`)
        for (let i = 0; i < countries.length; i++) {
            if (inputsRefs.current[9].current.value.toLowerCase() === countries[i].name.toLowerCase()) {
                if (!readOk.classList.contains(styles.iconShow))
                    readOk.classList.add(styles.iconShow)
                inputsRefs.current[9].current.parentElement.classList.remove(styles.borderRed)
                return true;
            }
            else if (i === countries.length - 1) {
                readOk.classList.remove(styles.iconShow)
                if (!inputsRefs.current[9].current.parentElement.classList.contains(styles.borderRed))
                    inputsRefs.current[9].current.parentElement.classList.add(styles.borderRed)
                return false;
            }
        }
    }

    function showHints(event) {
        let readValue = null;
        if (listInputRef.current)
            readValue = listInputRef.current.value.toLowerCase();
        // if (!readValue)
        //     listRef.current.classList.remove(styles.searchListOpen);
        // else {
        listRef.current.classList.add(styles.searchListOpen);
        listRef2.current.classList.add(styles.searchHintsOpen);
        // let counter = 0;
        listRef2.current.innerHTML = '';
        for (let i = 0; i < countries.length; i++) {
            if (readValue !== null && readValue.length > 0) {
                if (countries[i].name.toLowerCase().startsWith(readValue)) {
                    const element = document.createElement('span');
                    element.classList.add(styles.searchElement);
                    element.innerText = countries[i].name;

                    element.onclick = function () {
                        inputsRefs.current[9].current.value = countries[i].name;
                        hideLabel(event, 7);
                        checkCountry(event)
                        listRef.current.classList.remove(styles.searchListOpen);
                    }
                    listRef2.current.insertAdjacentElement('beforeend', element);
                }
            } else if (countries[i].name.toLowerCase()) {
                // counter++;
                const element = document.createElement('span');
                element.classList.add(styles.searchElement);
                element.innerText = countries[i].name;

                element.onclick = function () {
                    inputsRefs.current[9].current.value = countries[i].name;
                    hideLabel(event, 7);
                    checkCountry(event)
                    listRef.current.classList.remove(styles.searchListOpen);
                }
                listRef2.current.insertAdjacentElement('beforeend', element);
            }
            // if (counter > 10) {
            //     break;
            // }
        }
        // if (counter === 0) {
        //     listRef.current.classList.remove(styles.searchListOpen);
        // }
        // }
    }

    function showHintsSearch(event) {
        const readValue = event.target.value.toLowerCase();
        listRef.current.classList.add(styles.searchListOpen);
        listRef2.current.classList.add(styles.searchHintsOpen);
        let counter = 0;
        listRef2.current.innerHTML = '';
        for (let i = 0; i < countries.length; i++) {
            if (countries[i].name.toLowerCase().startsWith(readValue)) {
                counter++;
                const element = document.createElement('span');
                element.classList.add(styles.searchElement);
                element.innerText = countries[i].name;

                element.onclick = function () {
                    inputsRefs.current[9].current.value = countries[i].name;
                    hideLabel(event, 7);
                    checkCountry(event)
                    listRef.current.classList.remove(styles.searchListOpen);
                }
                listRef2.current.insertAdjacentElement('beforeend', element);
            }
            if (counter > 10) {
                break;
            }
        }
        // if (counter === 0) {
        //     listRef.current.classList.remove(styles.searchListOpen);
        // }

    }

    function hideList() {
        listRef.current.classList.remove(styles.searchListOpen);
        listRef2.current.classList.remove(styles.searchListOpen);
    }

    function validateFirstPage() {
        setIsLoading(true)
        setTimeout(() => {
            if (validateData()) {
                setIsLoading(false)
                setUserData(getAllData())
                setOpened(false)
                return true;
            } else {
                setIsLoading(false)
                return false;
            }
        }, 500)
    }

    useEffect(() => {
        fetch(`${apiUrls.mainUrl}${apiUrls.countires}`)
            .then((res) => res.json())
            .then((json) => {
                setCountries(json)
            })
    }, [])

    useEffect(() => {
        if (countries) {
            if (userData) {
                inputsRefs.current[6].current.value = userData.company_name
                inputsRefs.current[7].current.value = userData.street_number
                inputsRefs.current[8].current.value = userData.zip_code
                inputsRefs.current[9].current.value = userData.country_name
                inputsRefs.current[10].current.value = userData.street
                inputsRefs.current[11].current.value = userData.apartament_number
                inputsRefs.current[12].current.value = userData.city
                for (let i = 4; i <= 10; i++) {
                    if (i === 4) {
                        if (userData.company_name.length > 0) {
                            hideLabelByIndex(i)
                            showHideOkById(i)
                        }
                    }
                    else if (i === 9) {
                        if (userData.apartament_number.length > 0) {
                            hideLabelByIndex(i)
                            showHideOkById(i)
                        }
                    } else {
                        hideLabelByIndex(i)
                        showHideOkById(i)
                    }
                }
                //validateData()
            }
        }
    }, [countries, userData])

    return (<>
        <div className={styles.container}>
            <div className={styles.mainContainer}>
                <div className={styles.main}>
                    <form autoComplete={"off"} noValidate onSubmit={(event) => { event.preventDefault(); validateFirstPage() }} className={styles.vertical}>
                        <div className={styles.title}>{translationMix(['account', 'address', 'title'])}</div>
                        <div className={styles.horizontalMain} style={{ marginBottom: "20px" }}>
                            <div className={styles.verticalRight}>
                                <div className={styles.horizontal}>
                                    <div className={styles.vertical}>
                                        <div className={styles.inputContainer}>
                                            <input ref={inputsRefs.current[6]} className={styles.input} type={"text"} onInput={(event) => { hideLabel(event, 4); showHideOkReq(event, 4); }} />
                                            <div className={styles.labelContainer}>
                                                <div className={styles.text} data-label={4}>{translationMix(['account', 'create', 'lables', '4'])}</div>
                                            </div>
                                            <div className={styles.iconOk} data-ok={4} />
                                        </div>
                                        <div className={styles.inputContainer}>
                                            <input ref={inputsRefs.current[7]} className={styles.input} type={"text"} required onInput={(event) => { hideLabel(event, 5); showHideOkReq(event, 5); }} />
                                            <div className={styles.labelContainer}>
                                                <div className={styles.textReq} data-label={5}>{translationMix(['account', 'create', 'lables', '5'])}</div>
                                            </div>
                                            <div className={styles.iconOk} data-ok={5} />
                                        </div>
                                        <div className={styles.inputContainer}>
                                            <input ref={inputsRefs.current[8]} className={styles.input} type={"text"} required onInput={(event) => { hideLabel(event, 6); showHideOkReq(event, 6); }} />
                                            <div className={styles.labelContainer}>
                                                <div className={styles.textReq} data-label={6}>{translationMix(['account', 'create', 'lables', '6'])}</div>
                                            </div>
                                            <div className={styles.iconOk} data-ok={6} />
                                        </div>
                                        <div className={styles.inputContainer}>
                                            <input ref={inputsRefs.current[9]} className={styles.input} type={"text"} required onClick={(event) => { hideLabel(event, 7); checkCountry(); showHints(event); listInputRef.current.focus();}} />
                                            <div className={styles.labelContainer}>
                                                <div className={styles.textReq} data-label={7}>{translationMix(['account', 'create', 'lables', '7'])}</div>
                                            </div>
                                            <div className={styles.iconOk} data-ok={7} />
                                            <div ref={listRef} className={styles.searchListContainer} onBlur={()=>{hideList()}}>
                                                <div className={styles.inputContainer2}>
                                                    <input ref={listInputRef} className={styles.input2} type={"text"} onInput={(event) => { showHintsSearch(event) }}></input>
                                                    <div className={styles.btnClose} onClick={() => { hideList() }} />
                                                </div>
                                                <div className={styles.searchList} ref={listRef2}>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.vertical}>
                                        <div className={styles.inputContainer}>
                                            <input ref={inputsRefs.current[10]} className={styles.input} type={"text"} required onInput={(event) => { hideLabel(event, 8); showHideOkReq(event, 8); }} />
                                            <div className={styles.labelContainer}>
                                                <div className={styles.textReq} data-label={8}>{translationMix(['account', 'create', 'lables', '8'])}</div>
                                            </div>
                                            <div className={styles.iconOk} data-ok={8} />
                                        </div>
                                        <div className={styles.inputContainer}>
                                            <input ref={inputsRefs.current[11]} className={styles.input} type={"text"} onInput={(event) => { hideLabel(event, 9); showHideOkReq(event, 9); }} />
                                            <div className={styles.labelContainer}>
                                                <div className={styles.text} data-label={9}>{translationMix(['account', 'create', 'lables', '9'])}</div>
                                            </div>
                                            <div className={styles.iconOk} data-ok={9} />
                                        </div>
                                        <div className={styles.inputContainer}>
                                            <input ref={inputsRefs.current[12]} className={styles.input} type={"Miasto"} required onInput={(event) => { hideLabel(event, 10); showHideOkReq(event, 10); }} />
                                            <div className={styles.labelContainer}>
                                                <div className={styles.textReq} data-label={10}>{translationMix(['account', 'create', 'lables', '10'])}</div>
                                            </div>
                                            <div className={styles.iconOk} data-ok={10} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type={"submit"} className={`${styles.btn} ${styles.right}`}> {isLoading ? <LoaderAnimation width={20} height={20} isOne={true} /> : translationMix(['account', 'address', 'btn'])}</button>
                    </form>
                </div>
            </div>
        </div>
    </>
    )

}

export default Address;