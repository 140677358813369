import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./cartAdress.module.scss"
import sharedStyles from "../styles/cartStyles.module.scss"
import Modal from "../../../Components/Modal/modal";
import Address from "../../Account/Address/address";
import BillAddress from "../../Account/BillAddress/billAddress";
import { ThemeContext } from "../../../Components/Context/context";
import CustomSelector from "../../../Components/CustomSelector/customSelector";
import { getUserAddress } from "../../../apiCalls/userApiCalls";
import { translationMix } from "../../../Components/Helpers/translationMix";
import useWindowSize from "../../../Components/Helpers/useWindowSize";

function CartAdress({ setOption, userData, setUserData, userComment, setUserComment, userBillAddress, setUserBillAddress, userSendAddress, setUserSendAddress, isSame, setIsSame, adressBillSelectedId, setAdressBillSelectedId, adressSendSelectedId, setAdressSendSelectedId, coverRef, userReadAddress, setUserReadAddress, adressBillLabel, setAdressBillLabel }) {
    const [isModalOpened, setIsModalOpened] = useState(false)
    const [isModalBillOpened, setIsModalBillOpened] = useState(false)
    const [adressSendData, setAdressSendData] = useState(null)
    const [adressBillData, setAdressBillData] = useState(null)
    const [adressBillAllData, setAdressBillAllData] = useState(null)
    const [refreshList, setRefreshList] = useState(false)
    const [firstId, setFirstId] = useState(null)

    const secondAddressContainerRef = useRef(null)
    const secondAddressRef = useRef(null)
    const secondAddressBtnRef = useRef(null)
    const commentRef = useRef(null)
    const checkboxRef = useRef(null)
    const size = useWindowSize()
    const globalContext = useContext(ThemeContext)

    const deliveryAdressPopup = () => {
        return (
            <Modal opened={isModalOpened} setOpened={setIsModalOpened}>
                {/* <Address userData={userData} setUserData={setUserData} setOpened={setIsModalOpened} /> */}
                {userData ?
                    <Address userData={userData} setUserData={setUserData} setOpened={setIsModalOpened} /> :
                    <BillAddress userData={adressSendData} myId={1} save={true} setUserData={setAdressSendData} setOpened={setIsModalOpened} setRefresh={setRefreshList} />
                }

            </Modal>
        )
    }

    const billAdressPopup = () => {
        return (
            <Modal opened={isModalBillOpened} setOpened={setIsModalBillOpened}>
                {adressBillLabel ? adressBillLabel.length > 1 ?
                    <BillAddress userData={userBillAddress} myId={2} save={true} setUserData={setUserBillAddress} setOpened={setIsModalBillOpened} setRefresh={setRefreshList} /> :
                    <BillAddress userData={adressBillData} myId={2} save={true} create={true} setUserData={setUserBillAddress} setOpened={setIsModalBillOpened} setRefresh={setRefreshList} />
                    : <BillAddress userData={userBillAddress} myId={2} setUserData={setUserBillAddress} setOpened={setIsModalBillOpened} />}
            </Modal>
        )
    }

    function goNextPage() {
        saveComment()
        setOption(5)
    }

    function changeAddress(event) {
        if (event.target.checked) {
            // if (!secondAddressRef.current.classList.contains(styles.disable)) {
            secondAddressRef.current?.classList.add(styles.disable)
            secondAddressBtnRef.current?.classList.add(styles.disable)
            secondAddressContainerRef.current?.classList.add(styles.opacityHalf)
            setIsSame(true)
            // }
        } else if (!event.target.checked) {
            secondAddressRef.current?.classList.remove(styles.disable)
            secondAddressBtnRef.current?.classList.remove(styles.disable)
            secondAddressContainerRef.current?.classList.remove(styles.opacityHalf)
            setIsSame(false)
        }
    }

    function changeDeliveryAddress() {
        setIsModalOpened(true)
    }

    function changeBillingAddress() {
        if (!isSame)
            setIsModalBillOpened(true)
    }

    function saveComment() {
        const value = commentRef.current.value
        if (value)
            setUserComment(value)
    }

    useEffect(() => {

        if (!userReadAddress) {
            if (!userBillAddress) {
                //copy address when null
                if (userData) {
                    const myJsonData = {
                        firstname: userData.firstname,
                        lastname: userData.lastname,
                        company_name: userData.company_name,
                        street_number: userData.street_number,
                        zip_code: userData.zip_code,
                        id_country: userData.countryId,
                        country_name: userData.country_name,
                        street: userData.street,
                        apartament_number: userData.apartament_number,
                        city: userData.city,
                        phone: userData.phone
                    }
                    setUserBillAddress(myJsonData)
                }
            }

            setUserReadAddress(true)
        }
        if (isSame) {
            if (checkboxRef.current)
                checkboxRef.current.defaultChecked = true;
            secondAddressRef.current?.classList.add(styles.disable)
            secondAddressBtnRef.current?.classList.add(styles.disable)
            secondAddressContainerRef.current?.classList.add(styles.opacityHalf)
            // if (!secondAddressRef.current.classList.contains(styles.disable)) {
            //     secondAddressRef.current.classList.add(styles.disable)
            //     secondAddressBtnRef.current.classList.add(styles.disable)
            // }
        }
    }, [userBillAddress, userData, setUserBillAddress, isSame, userReadAddress, setUserReadAddress])

    useEffect(() => {
        if (!adressSendData && globalContext.authToken) {
            getUserAddress(globalContext.authToken).then(res => {
                if (res.status !== 401) {
                    let tmpArray = []
                    res.forEach((item, index) => {
                        tmpArray.push([item.id, `"` + item.company_name + `" ` + item.street + " " + item.street_number])
                    })
                    setAdressBillLabel(tmpArray)
                    setAdressBillAllData(res)
                    if (res.length > 0) {
                        setFirstId(res[0].id)
                        // setAdressBillSelectedId(res[0].id)
                        // setAdressSendSelectedId(res[0].id)
                        // setAdressSendData(res[0].firstname)
                        // setUserSendAddress(res[0])
                        // setUserBillAddress(res[0])
                    }
                }
            })
        }
        // eslint-disable-next-line
    }, [globalContext.authToken, adressSendData, setAdressBillSelectedId, setAdressSendSelectedId, setUserBillAddress, setUserSendAddress])

    useEffect(() => {
        if (refreshList) {

            if (coverRef.current) {
                coverRef.current.classList.add(styles.coverShow)
                setTimeout(() => {
                    coverRef.current.classList.add(styles.opacityFull)
                }, 1)

                setTimeout(() => {
                    if (coverRef.current) {
                        coverRef.current.classList.remove(styles.opacityFull)
                        setTimeout(() => {
                            if (coverRef.current)
                                coverRef.current.classList.remove(styles.coverShow)
                        }, 800)
                    }
                }, 400)
            }

            getUserAddress(globalContext.authToken).then(res => {
                let tmpArray = []
                res.forEach((item, index) => {
                    tmpArray.push([item.id, `"` + item.company_name + `" ` + item.street + " " + item.street_number])
                })
                setAdressBillLabel(tmpArray)
                setAdressBillAllData(res)

                if (adressBillSelectedId) {
                    for (let i = 0; i < res.length; i++) {
                        if (res[i].id === adressBillSelectedId) {
                            setAdressSendData(res[i])
                            setUserSendAddress(res[i])
                            break;
                        }
                    }
                }

                if (adressSendSelectedId) {
                    for (let i = 0; i < res.length; i++) {
                        if (res[i].id === adressSendSelectedId) {
                            setAdressBillData(res[i])
                            setUserBillAddress(res[i])
                            break;
                        }
                    }
                }
            })
            setRefreshList(null)
        }
        // eslint-disable-next-line
    }, [globalContext.authToken, refreshList, adressBillSelectedId, adressSendSelectedId, coverRef, setUserBillAddress, setUserSendAddress])


    useEffect(() => {
        if (adressBillAllData) {
            for (let i = 0; i < adressBillAllData.length; i++) {
                if (adressBillSelectedId) {
                    if (adressBillAllData[i].id === adressBillSelectedId) {
                        setAdressSendData(adressBillAllData[i])
                        setUserSendAddress(adressBillAllData[i])
                        break;
                    }
                } else {
                    if (adressBillAllData[i].id === firstId) {
                        setAdressSendSelectedId(firstId)
                        setAdressSendData(adressBillAllData[i])
                        setUserSendAddress(adressBillAllData[i])
                        break;
                    }
                }
            }
        }
    }, [adressBillSelectedId, adressBillAllData, setUserSendAddress, firstId, setAdressSendSelectedId])

    useEffect(() => {
        if (adressBillAllData) {
            for (let i = 0; i < adressBillAllData.length; i++) {
                if (adressSendSelectedId) {
                    if (adressBillAllData[i].id === adressSendSelectedId) {
                        setAdressBillData(adressBillAllData[i])
                        setUserBillAddress(adressBillAllData[i])
                        break;
                    }
                } else {
                    if (adressBillAllData[i].id === firstId) {
                        setAdressBillSelectedId(firstId)
                        setAdressBillData(adressBillAllData[i])
                        setUserBillAddress(adressBillAllData[i])
                        break;
                    }
                }
            }
        }
    }, [adressSendSelectedId, adressBillAllData, setUserBillAddress, firstId, setAdressBillSelectedId])

    return (
        <div className={styles.container}>
            {deliveryAdressPopup()}
            {billAdressPopup()}
            <div className={styles.main}>

                <div className={styles.containerTop}>
                    <div className={sharedStyles.text2}>{translationMix(['cart', 'address', 'title'])}:</div>
                    {adressBillLabel ?
                        <div className={styles.content}>
                            <div className={styles.select}>
                                <CustomSelector data={adressBillLabel} myId={1} returnRealId={true}
                                    styleId={2} selectedId={adressBillSelectedId} setSelectorOption={setAdressBillSelectedId} tagText={translationMix(['cart', 'address', 'selectorLabels', '0'])} />
                            </div>
                            {size.width > 900 ?
                                <div className={styles.select}>
                                    {!isSame ? adressBillLabel ? adressBillLabel.length > 1 ? <CustomSelector data={adressBillLabel} myId={2} returnRealId={true}
                                        styleId={2} selectedId={adressSendSelectedId} setSelectorOption={setAdressSendSelectedId} tagText={translationMix(['cart', 'address', 'selectorLabels', '1'])} /> : null : null : null}
                                </div> : null}

                        </div> : null}
                    {size.width > 900 ?
                        <div className={styles.content2}>
                            <input id={"sameAddressCheckbox"} ref={checkboxRef} className={styles.checkboxNormal} type={"checkbox"} onInput={(e) => { changeAddress(e) }} />
                            <label htmlFor={"sameAddressCheckbox"} className={`${styles.textSmall} ${styles.clickable}`}>{translationMix(['cart', 'address', 'labels', '2'])}</label>
                        </div> : null}

                </div>
                <div className={styles.containerMid}>
                    <div className={styles.columnLeft}>
                        <div className={`${sharedStyles.textBig} ${styles.textTitle}`}>{translationMix(['cart', 'address', 'labels', '0'])}</div>
                        {adressSendData ?
                            <p className={`${sharedStyles.textSmall} ${styles.mid}`}>
                                {adressSendData.firstname} {adressSendData.lastname} < br />
                                {adressSendData.company_name}<br />
                                {adressSendData.street} {adressSendData.street_number}/{adressSendData.apartament_number}< br />
                                {adressSendData.zip_code} {adressSendData.city}< br />
                                {adressSendData.country_name}< br />
                                {adressSendData.phone}<br />
                                {adressSendData.nip}</p> :
                            userData ?
                                <p className={`${sharedStyles.textSmall} ${styles.mid}`}>
                                    {userData.firstname} {userData.lastname} < br />
                                    {userData.company_name ? <>{userData.company_name}< br /> </> : <></>}
                                    {userData.street} {userData.street_number}< br />
                                    {userData.zip_code} {userData.city}< br />
                                    {userData.country_name}< br />
                                    {userData.phone}</p>
                                // : <p className={`${sharedStyles.textSmall} ${styles.mid}`}>ERROR</p>}
                                : <p className={`${sharedStyles.textSmall} ${styles.mid}`}></p>}
                        <div className={styles.btnUpdate} onClick={() => { changeDeliveryAddress() }}>{translationMix(['cart', 'address', 'btn', '0'])}</div>
                    </div>
                    {size.width <= 900 ?
                        <div className={styles.content2}>
                            <input ref={checkboxRef} className={styles.checkboxNormal} type={"checkbox"} onInput={(e) => { changeAddress(e) }} />
                            <div className={styles.textSmall}>{translationMix(['cart', 'address', 'labels', '2'])}</div>
                        </div> : null}

                    {size.width <= 900 ? !isSame ? adressBillLabel ? adressBillLabel.length > 1 ?
                        <div className={styles.selector}>
                            <CustomSelector data={adressBillLabel} myId={2} returnRealId={true}
                                styleId={2} selectedId={adressSendSelectedId} setSelectorOption={setAdressSendSelectedId} tagText={translationMix(['cart', 'address', 'selectorLabels', '1'])} />
                        </div> : null : null : null : null}
                    <div ref={secondAddressContainerRef} className={styles.columnCenter}>
                        <div className={`${sharedStyles.textBig} ${styles.textTitle}`}>{translationMix(['cart', 'address', 'labels', '1'])}</div>

                        {adressBillData ? adressBillLabel ? adressBillLabel.length > 1 ?
                            <p ref={secondAddressRef} className={`${sharedStyles.textSmall} ${styles.mid}`}>
                                {adressBillData.firstname} {adressBillData.lastname} < br />
                                {adressBillData.company_name}<br />
                                {adressBillData.street} {adressBillData.street_number}/{adressBillData.apartament_number}< br />
                                {adressBillData.zip_code} {adressBillData.city}< br />
                                {adressBillData.country_name}< br />
                                {adressBillData.phone} <br />
                                {adressBillData.nip}
                            </p> : null : null :
                            userBillAddress ?
                                <p ref={secondAddressRef} className={`${sharedStyles.textSmall} ${styles.mid}`}>
                                    {userBillAddress.firstname} {userBillAddress.lastname}<br />
                                    {userBillAddress.company_name ? <>{userBillAddress.company_name}< br /> </> : <></>}
                                    {userBillAddress.street} {userBillAddress.street_number}/{userBillAddress.apartament_number}< br />
                                    {userBillAddress.zip_code} {userBillAddress.city}< br />
                                    {userBillAddress.country_name}< br />
                                    {userBillAddress.phone}<br />
                                    {userBillAddress.nip}
                                </p>
                                : <p ref={secondAddressRef} className={`${sharedStyles.textSmall} ${styles.mid}`}></p>}
                        <div ref={secondAddressBtnRef} className={styles.btnUpdate} onClick={() => { changeBillingAddress() }}>{adressBillLabel ? adressBillLabel.length > 1 ? translationMix(['cart', 'address', 'btn', '0']) : translationMix(['cart', 'address', 'btn', '1']) : translationMix(['cart', 'address', 'btn', '0'])}</div>
                    </div>
                </div>
                <div className={styles.containerBot}>
                    <div className={styles.top}>
                        <div className={styles.commentContainer}>
                            <textarea ref={commentRef} className={styles.input} type="text" placeholder={translationMix(['cart', 'address', 'comment'])} defaultValue={userComment} onInput={() => saveComment()}></textarea>
                            {/* <div className={styles.btnSave} onClick={() => { saveComment() }}>Zapisz</div> */}
                        </div>
                    </div>
                    <div className={styles.bot}>
                        <div className={styles.btnGrey} onClick={() => { goNextPage() }}>{translationMix(['cart', 'address', 'btn', '2'])}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CartAdress;