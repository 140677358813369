import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/core';

import useWindowSize from '../../../../Components/Helpers/useWindowSize';
import styles from './gallery.module.scss'

function FullScreenGallery({ data, bigRef, showGallery }) {
    //size.width - 150
    const size = useWindowSize()
    return (
        <div className={styles.fullPreviewContainer} data-gallery={0}>
            <div className={`${size.width - 50 > size.height ? styles.fullPreviewGalleryHeight : styles.fullPreviewGalleryWidth}`}>
                
                <Splide hasTrack={false} aria-label="..." className={styles.fullPreview} ref={bigRef}
                    options={{
                        perPage: 1,
                        gap: '50px',
                        rewind: false,
                        pagination: false, //te kropki na dole
                    }}
                >

                    <div className="splide__arrows">
                        <button className={`splide__arrow splide__arrow--prev ${styles.arrowBigPreviwe} ${styles.leftArrowBigPrev}`} />
                        <button className={`splide__arrow splide__arrow--next ${styles.arrowBigPreviwe} ${styles.rightArrowBigPrev}`} />
                    </div>
                    <div className={styles.loopMinus} onClick={() => { showGallery() }}></div>
                    <SplideTrack >
                        {data.map((item, index) => (
                            <SplideSlide key={index}>
                                <div className={`${size.width - 50 > size.height ? styles.img1BigHeight : styles.img1BigWidth}`} style={{ backgroundImage: `url("${item.image_url}")` }} />
                            </SplideSlide>
                        ))}
                    </SplideTrack>
                </Splide>
            </div>
        </div>
    )
}

export default FullScreenGallery