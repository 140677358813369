import React, { useEffect, useState } from "react";

import Header from "../Header/header";
import Footer from "../Footer/footer";
import Form from "./Form/form";
import { getAllProductCart } from "../../apiCalls/productApiCalls";

function CartContainer({ handleLogin }) {
    const [cartDataContainer, setCartDataContainer] = useState(null)

    useEffect(() => {
        getAllProductCart().then(res => {
            if (res.status) {
                if (res.status === true) {
                    setCartDataContainer(res.cart)
                }
            }
        })
    }, [])

    return (
        <>
            <Header cartData={cartDataContainer} />
            <Form handleLogin={handleLogin} setCartData={setCartDataContainer} cartData={cartDataContainer} />
            <Footer />
        </>
    )
}

export default CartContainer;