import styles from "./background.module.scss"
// import imageBackground from "../../../assets/img/Blog/imageBackground.jpg"

function Background({ image, text }) {
    return (
        <div className={styles.containerBackground}>
            <div className={styles.background} style={{ backgroundImage: `url(${image})` }}>
                <div className={styles.shadow} />
            </div>
            <div className={styles.backgroundText}>{text}</div>
        </div>
    )
}

export default Background