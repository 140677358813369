const re = /^\+?([0-9]+)\)?[-. ]?([0-9]+)[-. ]?([0-9]+)?[-. ]?([0-9]+)?$/;
export function validatePhone(value) {
    value = value.toString().trim()
    if (re.test(value))
        return true
    else
        return false
}

export function validatePhoneRef(ref) {
    let value = ref.current.value
    value = value.toString().trim()
    if (re.test(value))
        return true
    else
        return false
}