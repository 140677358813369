import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Background from "../../Components/Elements/Backgorund/background";
import BigWide from "../../Components/Elements/BigWide/bigWide";
import { Loader } from "../../Components/Loader/loader";
import SliderItem from "../../Components/Sliders/Post/SliderItem/sliderItem";
import styles from "./post.module.scss"
import staticImageBig from "../../assets/img/Blog/photoBig.jpg"
import ProductSmall from "../../Components/Elements/ProductSmall/productSmall";
import useWindowSize from "../../Components/Helpers/useWindowSize";
import Medium from "../../Components/Elements/medium/medium";
import stringToUrl from "../../Components/Helpers/stringToUrl";
import { Helmet } from "react-helmet";
import { getPost } from "../../apiCalls/blogApiCalls";
import { translationMix } from "../../Components/Helpers/translationMix";

function Post() {
    const [data, setData] = useState(null)
    const [monthText, setMonthText] = useState(null)
    const [contentToShow, setContentToShow] = useState(null)
    const size = useWindowSize();
    const { lang, id } = useParams();
    let number = -1;
    const myDataBig = { title: "Obudowy komputerowe, a obudowy serwerowe", description: "Zakup serwera dla firm wiąże się z niemałymi wydatkami. Przed podjęciem decyzji warto skonsultować się ze specjalistami branży IT...", image: staticImageBig, tag: "Hardwear, technologie", date: "20 listopada,2022" }

    useEffect(() => {
        let readId = id
        readId = readId.split('-')[0]
        getPost(readId).then(res => {
            if (`${res.id}-${stringToUrl(res.title)}` !== id) {
                window.location.pathname = `${lang}/post/${res.id}-${stringToUrl(res.title)}`
            } else
                setData(res)
        })
    }, [lang, id]);

    useEffect(() => {
        if (data) {
            var options = { month: 'long' };
            var readDate = new Date(data.updated_at)
            var tmp = []
            //en-US
            if (lang === "pl")
                tmp.push(readDate.toLocaleDateString("pl-PL", options))
            else if (lang === 'cz')
                tmp.push(readDate.toLocaleDateString("cs-CZ", options))
            else if (lang === 'de')
                tmp.push(readDate.toLocaleDateString("de-DE", options))
            else
                tmp.push(readDate.toLocaleDateString("en-US", options))
            tmp.push(readDate.getDay())
            tmp.push(readDate.getFullYear())
            setMonthText([...tmp])

            var readText = data.text.split('\r\n')
            console.log(readText)
            let save = true;
            let arrayContnet = []
            let content = {}
            content.title = "";
            content.text = "";
            content.img = "";

            let count = -1;
            for (let i = 0; i < readText.length; i++) {
                if (readText[i].startsWith("<h1>")) {
                    if (save) {
                        arrayContnet.push(content)
                        content = {}
                        content.title = readText[i];
                        content.text = "";
                        content.img = "";
                        count = 0;
                    }
                }
                else if (readText[i].startsWith("<h4>") || readText[i].startsWith("<h3>") || readText[i].startsWith("<h2>")) {
                    if (save) {
                        arrayContnet.push(content)
                        content = {}
                        content.title = "";
                        content.text = "";
                        content.img = "";
                    }
                    save = true;
                    content.text = readText[i]
                }
                else if (save) {
                    if (count === 0) {
                        if (readText[i].startsWith("<p><img")) {
                            content.img = readText[i].match(/"([^"]+)"/)[1]
                            count = -1;
                        } else {
                            content.text += readText[i]
                        }
                    } else {
                        content.text += readText[i]
                    }
                }
                if (i === readText.length - 1) {
                    arrayContnet.push(content)
                }
            }
            setContentToShow([...arrayContnet])
        }
    }, [data, lang]);

    return (
        <>
            <Loader data={data} />
            {data ?
                <>
                    <Helmet>
                        <title>{lang === 'pl' ? `${data.title} - Sklep internetowy Bizserver` : lang === 'en' ? `${data.title} - Bizserver online store` : lang === 'de' ? `${data.title} - Online-Shop von Bizserver` : `${data.title} - Internetový obchod Bizserver`}</title>
                        <meta name="description" content={data.title} />
                    </Helmet>
                    <Background image={data.image_url} text={data.title} />
                    <div className={styles.container}>
                        <div className={styles.main}>
                            <div className={styles.containerWhite}>
                                <div className={`${styles.content} ${styles.small}`}>
                                    <div className={styles.titleContainer}>
                                        {data.tags ? data.tags[0] !== "" ?
                                            <div className={styles.tag}>{data.tags.join(", ")}</div>
                                            : null : null}
                                        <div className={styles.descriptionContainer}>
                                            <div className={styles.description}>{translationMix(['post', 'readed'])}: {data.views}</div>
                                            <div className={styles.description}>{translationMix(['post', 'update'])}: {monthText ? monthText[0].substring(0, 3).toUpperCase() : null} {monthText ? monthText[1] : null} , {monthText ? monthText[2] : null}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${styles.content} ${styles.small}`}>
                                    <div className={`${styles.content} ${styles.medium}`}>
                                        {/* <div className={styles.btnSearch} /> */}
                                        <div className={styles.titleContainer2}>
                                            <div className={styles.title} dangerouslySetInnerHTML={{ __html: data.summary }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {contentToShow ? contentToShow.map((item, index) => {
                                if (item.title !== "")
                                    if (item.title.startsWith("<h1>")) {
                                        number++
                                        return (
                                            <div key={index} className={styles.containerWhite}>
                                                <div className={`${styles.content}`}>
                                                    <BigWide title={item.title} text={item.text} img={item.img} index={number} />
                                                    {number === 0 ?
                                                        <div className={styles.center} style={{ marginBottom: "50px" }}>
                                                            <SliderItem >
                                                                <ProductSmall data={myDataBig} />
                                                                <ProductSmall data={myDataBig} />
                                                                <ProductSmall data={myDataBig} />
                                                                <ProductSmall data={myDataBig} />
                                                            </SliderItem>
                                                        </div> : null}
                                                </div>
                                            </div>)
                                    }
                                if (item.text.startsWith("<h2>")) {
                                    return (
                                        <div key={index} className={styles.containerWhite}>
                                            <div className={`${styles.content} ${styles.small}`}>
                                                <div className={styles.subtitleContainer} dangerouslySetInnerHTML={{ __html: item.text }} />
                                            </div>
                                        </div>)
                                }
                                if (item.text.startsWith("<h3>")) {
                                    return (
                                        <div key={index} className={styles.containerGrey}>
                                            <div className={`${styles.content} ${styles.small} ${size.width <= 1000 ? styles.center : ''}`}>
                                                <div className={styles.subtitleContainer} dangerouslySetInnerHTML={{ __html: item.text }} />
                                            </div>
                                        </div>
                                    )
                                }
                                if (item.text.startsWith("<h4>")) {
                                    return (
                                        <div key={index} className={styles.containerWhite}>
                                            <div className={`${styles.content}`} dangerouslySetInnerHTML={{ __html: item.text }} />
                                        </div>
                                    )
                                }
                                if (item.text.startsWith("<p>")) {
                                    return (
                                        <div key={index} className={styles.containerWhite}>
                                            <div className={`${styles.content}`} dangerouslySetInnerHTML={{ __html: item.text }} />
                                        </div>
                                    )
                                }
                                return (null)
                            }) : null}
                            <div className={styles.containerWhite}>
                                <div className={`${styles.content} ${styles.small}`}>
                                    <div className={styles.textContainer}>
                                        <p className={styles.point}>{translationMix(['post', 'author'])}: {data.author}</p>
                                        <p className={styles.point}>{translationMix(['post', 'categories'])}: <span >{data.category_name}</span></p>
                                        {data.tags ? data.tags[0] !== "" ?
                                        <p className={styles.point}>{translationMix(['post', 'tags'])}: <span >{data.tags.join(", ")}</span></p> : null : null}
                                    </div>
                                </div>
                            </div>
                            {/* <div className={styles.containerWhite}>
                                <div className={`${styles.content} ${styles.big}`}>
                                    {data.comments.length > 0 ? <>
                                        <div className={styles.allComments}>
                                            {data.comments.map((item, index) => {
                                                return (
                                                    <div className={styles.commentContainer}>
                                                        <div key={index} className={styles.column}><div className={styles.img} /> <div className={styles.author}>{item.name}</div></div>
                                                        <div className={`${styles.column} ${styles.columnBig}`}>
                                                            <div className={styles.data} >{ConvertDate(item.created_at, lang, true)}</div>
                                                            <div className={`${styles.text} ${styles.minHeight}`}>{item.content}</div>                                                </div>
                                                        <div className={`${styles.column} ${styles.columnSmall}`} />
                                                    </div>
                                                )
                                            })}
                                        </div></> : null}
                                    <div className={styles.moreSpace} />
                                    <div className={styles.commentContainer}>
                                        <div className={styles.column}><div className={styles.img} /></div>
                                        <div className={`${styles.column} ${styles.columnBig}`}>
                                            <div className={styles.titleComment}>{translation.post.comment}</div>
                                            <input placeholder={translation.post.namePlaceholder}></input>
                                            <textarea placeholder={translation.post.commentPlaceholder} cols={40} rows={12}></textarea>
                                            <Link to={"/"} className={styles.link}><strong>{translation.post.login[0]}</strong> {translation.post.login[1]}</Link>
                                        </div>
                                        <div className={`${styles.column} ${styles.columnSmall}`} />
                                    </div>
                                </div>
                                <div className={styles.moreSpace} />
                            </div> */}
                            <div className={styles.containerGreyEffect}>
                                <div className={`${styles.content} ${styles.big}`}>
                                    <div className={styles.titleContainer}>
                                        <div className={styles.title}>{translationMix(['post', 'other'])}</div>
                                    </div>
                                    <div className={styles.postList}>
                                        {data.other_posts ?
                                            data.other_posts.map((item, index) => {
                                                return (index <= data.other_posts.length - (3 + data.other_posts.length % 2) ? <Medium key={index} data={item} /> : <Medium key={index} data={item} isTextUnder={true} />)
                                            }) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </> : null
            }
        </>
    )
}

export default Post;