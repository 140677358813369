import styles from "./customSelector.module.scss"
import { useEffect, useState } from "react";
import { translationMix } from "../Helpers/translationMix";

function CustomSelector({ data = null, myId = 1, styleId = 1, setSelectorOption, tagText = translationMix(['itemList','selector','label']), selectedIndex = null, selectedId = null, returnRealId = false }) {
    const [sliderTime, setSliderTimeOut] = useState(null)

    const slideDownUpHandler = () => {
        const subMenu = document.querySelectorAll(`[slide~="${myId}"]`)[0]
        const arrow = document.querySelectorAll(`[arrow~="${myId}"]`)[0]
        let tmp = data ? subMenu.scrollHeight : 50;
        if (subMenu.style.height === '0px' || subMenu.style.height === '') {
            clearTimeout(sliderTime)
            subMenu.style.maxHeight = `${tmp}px`;
            subMenu.style.height = `${tmp}px`;
            subMenu.style.opacity = 1;
            arrow.classList.add(styles.open)
            if (data) {
                const myTime = setTimeout(() => {
                    subMenu.classList.add(styles.sliderOpen)
                }, 300)
                setSliderTimeOut(myTime)
            }
        } else {
            clearTimeout(sliderTime)
            if (data) {
                subMenu.classList.remove(styles.sliderOpen)
            }
            const myTime = setTimeout(() => {
                subMenu.style.height = '0px';
                subMenu.style.opacity = 0;
                arrow.classList.remove(styles.open)
            }, 1)
            setSliderTimeOut(myTime)


        }
    }

    function handleChangeCategory(readText, id) {
        const field = document.querySelectorAll(`[field~="${myId}"]`)[0]
        field.textContent = returnRealId ? readText : readText.item
        setSelectorOption(id)
    }

    useEffect(() => {
        if (selectedIndex) {
            const field = document.querySelectorAll(`[field~="${myId}"]`)[0]
            field.textContent = data[selectedIndex - 1]
        }
    }, [selectedIndex, data, myId])

    useEffect(() => {
        if (selectedId) {
            const field = document.querySelectorAll(`[field~="${myId}"]`)[0]
            for(let i = 0; i < data.length; i++){
                if(data[i][0] === selectedId){
                    field.textContent = data[i][1]
                    break;
                }
            }   
        }
    }, [selectedId, data, myId])

    return (<>
        <div className={`${styleId === 2 ? styles.container2 : styles.container}`} >
            <div className={styles.label}>{tagText}</div>
            <div className={`${styleId === 2 ? styles.selectButton2 : styles.selectButton}`} onClick={() => { slideDownUpHandler(0) }}>
                <div className={`${styleId === 2 ? styles.field2 : styles.field}`} field={myId}></div>
                <div className={styles.arrow} arrow={myId}></div>
            </div>
            <div className={`${styleId === 2 ? styles.optionsMenu2 : styles.optionsMenu}`} style={{ zIndex: 1000 }} slide={myId} >
                {data ?
                    data.map((item, index) => {
                        return (
                            <div className={styles.menuButton} key={index} onClick={() => { if (returnRealId) {handleChangeCategory(item[1], item[0]);} else{ handleChangeCategory({ item }, index + 1);} slideDownUpHandler(); }}>{returnRealId ? item[1] : item}</div>
                        )
                    }) : null}
            </div>
        </div>
    </>
    )
}

export default CustomSelector