import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { getTextPage } from "../../../apiCalls/sharedApiCalls";
import { Loader } from "../../../Components/Loader/loader";

import styles from './contact.module.scss'

function Contact() {
    const [data, setData] = useState(null)
    const { lang } = useParams();

    useEffect(() => {
        if (!data) {
            getTextPage(2).then(res => {
                setData(res.text)
            })
        }
    }, [data])

    return (
        <>
        <Helmet>
            <title>{lang === 'pl' ? 'Kontakt - Sklep internetowy Bizserver' : lang === 'en' ? 'Contact - Bizserver online store' : lang === 'de' ? 'Kontakt - Online-Shop von Bizserver' : 'Kontakt - internetový obchod Bizserver'}</title>
            <meta name="description" content={"Kontakt - Sklep internetowy Bizserver"} />
        </Helmet>
            <Loader justLoad={true} />
            <div className={styles.container}>
                <div className={styles.containerMain}>
                    <div className={styles.infoContainer}>
                        <p className={styles.text} dangerouslySetInnerHTML={{ __html: data }}>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact;