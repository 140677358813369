import styles from './productSmall.module.scss'
import numberWithSpace from "../../../Components/Helpers/numberWithSpace";
import { translationMix } from '../../Helpers/translationMix';

function ProductSmall({ data }) {

    return (
        <div className={styles.product}>
            <div className={styles.img} style={{ backgroundImage: `url("${data.image}")` }} />
            <div className={styles.descriptionContainer}>
                <div className={styles.productName}>Obudowa Supermicro </div>
                <div className={styles.productName}>CSE-735D4-668B</div>
            </div>
            <div className={styles.price}>
                <div className={styles.cost}>
                    <div className={styles.priceOld}>{numberWithSpace(2323256.23)} {translationMix(['shared', 'price', 'net'])}</div>
                    <div className={styles.priceNew}>{numberWithSpace(1223256.23)} {translationMix(['shared', 'price', 'net'])}</div>
                    <div className={styles.priceNormalGross}>{numberWithSpace(2111156.23)} {translationMix(['shared', 'price', 'gros'])}</div>
                    {/* <div className={styles.amountNone}>{translation.shared.amount[2]}</div> */}
                </div>
                {/* <div className={styles.cartButton}></div> */}
            </div>
        </div>
    )
}

export default ProductSmall