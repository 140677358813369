import styles from './big.module.scss'
import convertDate from '../../Helpers/convertDate';
import { useParams } from 'react-router-dom';
import stringToUrl from '../../Helpers/stringToUrl';
import { translationMix } from '../../Helpers/translationMix';

function Big({ data, shadowLight = false }) {
    const { lang } = useParams();
    return (
        <div className={styles.container}>
            <div className={styles.img} style={{ backgroundImage: `url("${data.image_url}")` }}></div>
            <div className={`${shadowLight ? styles.shadow2 : styles.shadow}`} />
            <div className={styles.content}>
                {data.tags ? data.tags[0] !== "" ? 
                <div className={`${shadowLight ? styles.tagLight : styles.tag}`}>{data.tags.join(", ")}</div> : null : null}
                <div className={styles.date}>{convertDate(data.updated_at, lang)}</div>
                <div className={styles.title}>{data.title}</div>
                <a href={`/${lang}/post/${data.id}-${stringToUrl(data.title)}`} className={styles.btnGo}>{translationMix(['shared', 'button', 'read'])}</a>
            </div>
        </div>
    )
}

export default Big