import { useContext, useEffect, useRef, useState } from "react";
import { Redirect, useParams } from "react-router-dom"
import { ThemeContext } from "../Context/context";

const PrivateRoute = (props) => {
    const { lang } = useParams();
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const globalContextRef = useRef(useContext(ThemeContext))

    // function isAuthenticated() {
    //     if (globalContext.authToken) {
    //         return true
    //     }
    //     return false
    // }

    useEffect(()=>{
        globalContextRef.current.checkToken().then(res => {
            if (res){
                setIsAuthenticated(res)
            }else {
                setIsAuthenticated(false)
            }
        })
    },[])

    return isAuthenticated ? props.children
        : isAuthenticated !== null ? 
        <Redirect to={{pathname: `/${lang}/login`, state: { prevPath: window.location.href }}} /> : null
}

export default PrivateRoute