import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { getTextPage } from "../../../apiCalls/sharedApiCalls";
import stringToUrl from "../../../Components/Helpers/stringToUrl";
import { translationMix } from "../../../Components/Helpers/translationMix";
import { Loader } from "../../../Components/Loader/loader";
import MenuLeftMobile from "../../../Components/MenuLeftMobile/menuLeftMobile";

import styles from './policy.module.scss'

function Policy({ menuData, menuIdSelected }) {
    const [data, setData] = useState(null)
    const { lang } = useParams();
    useEffect(() => {
        if (!data) {
            getTextPage(4).then(res => {
                setData(res.text)
            })
        }
    }, [data])
    return (
        <>
            <Helmet>
                <title>{lang === 'pl' ? 'Polityka prywatności - Sklep internetowy Bizserver' : lang === 'en' ? 'Privacy policy - Bizserver online store' : lang === 'de' ? 'Datenschutz-Bestimmungen - Online-Shop von Bizserver' : 'Zásady ochrany osobních údajů - internetový obchod Bizserver'}</title>
                <meta name="description" content={lang === 'pl' ? 'Polityka prywatności - Sklep internetowy Bizserver' : lang === 'en' ? 'Privacy policy - Bizserver online store' : lang === 'de' ? 'Datenschutz-Bestimmungen - Online-Shop von Bizserver' : 'Zásady ochrany osobních údajů - internetový obchod Bizserver'} />
            </Helmet>
            <Loader justLoad={true} />
            <div className={styles.container}>
                <MenuLeftMobile menuData={menuData} menuId={menuIdSelected} />
                <div className={styles.containerMain}>
                    <div className={styles.containerLeft}>
                        <div className={`${styles.menu}`}>
                            <div className={styles.menuTitle}>{translationMix(['header','menu','info','0'])}</div>
                            {menuData.map((item, index) => {
                                return (
                                    <div className={styles.point} key={index}>
                                        <Link to={`${stringToUrl(item[1])}`}><div className={`${styles.myLink} ${menuIdSelected === index ? styles.active : ''}`}>{item[0]}</div></Link>
                                    </div>)
                            })}
                        </div>
                    </div>
                    <div className={styles.containerRight}>
                        <div className={styles.textContainer}>
                            <p className={styles.text} dangerouslySetInnerHTML={{ __html: data }}>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Policy;