import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { Loader } from "../../Components/Loader/loader";

import styles from './noPage.module.scss'

function NoPage() {
    const { lang } = useParams();
    return (
        <>
            <Helmet>
                <title>{lang === 'pl' ? 'Błąd 404 - Sklep internetowy Bizserver' : lang === 'en' ? 'Error 404 - Bizserver online store' : lang === 'de' ? 'Fehler 404 - Bizserver Online-Shop' : 'Chyba 404 - internetový obchod Bizserver'}</title>
                <meta name="description" content={lang === 'pl' ? "Nie można odnaleźć strony" : lang === 'en' ? 'Can not find the site' : lang === 'de' ? 'Kann die Seite nicht finden' : 'Nelze najít web'} />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <Loader justLoad={true} />
            <div className={styles.container}>
                <div className={styles.containerMain}>
                    <div className={styles.image} />
                </div>
            </div>
        </>
    )
}

export default NoPage;