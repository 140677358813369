function numberWithSpace(x) {
    x = parseFloat(x).toFixed(2)
    let endNumber = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".",",");
    if(endNumber.indexOf(',') < 0){
        endNumber += ",00"
    } else{
        if(endNumber.substr(endNumber.indexOf(",") + 1).length === 1){
            endNumber += "0"
        }
    }
    return endNumber
}

export default numberWithSpace