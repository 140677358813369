import React, { useRef } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import useWindowSize from "../Helpers/useWindowSize";
import styles from './menuWrapper.module.scss'
import stringToUrl from "../Helpers/stringToUrl";
import { logout } from "../../apiCalls/authApiCalls";
import { translationMix } from "../Helpers/translationMix";

function Menu({ data, isAuthenticated, setIsAuthenticated }) {
    const { lang } = useParams();
    const idRef = useRef(null);
    const size = useWindowSize();
    const location = useLocation()
    const url = location.pathname.split('/')

    const showHideMenu = () => {
        let button = document.getElementById("btn")
        let menu = document.getElementById("menuContainer")
        if (button.classList.contains(styles.activeMain)) {
            button.classList.remove(styles.activeMain)
            menu.classList.remove(styles.menuContainerShow)
            if (size.width <= 800) {
                document.getElementsByTagName('body')[0].style.overflowY = "auto"
            }
        } else {
            button.classList.add(styles.activeMain);
            menu.classList.add(styles.menuContainerShow)
            if (size.width <= 800) {
                document.getElementsByTagName('body')[0].style.overflowY = "hidden"
            }
        }
    }

    function logoutFormMobileMenu() {
        logout().then(res => {
            showHideMenu()
            setIsAuthenticated(false)
            //redirect if current page is user data page
            let currentPage = window.location.pathname.substring(lang.length + 2)
            if (currentPage === "account-address" || currentPage === "account-order" || currentPage === "account-user") {
                window.location.reload();
            } else if (currentPage === "cart") {
                window.location.href = "/"
            }
        })
    }

    return (
        <>
            <div className={`${styles.btn} ${styles.notActive}`} id={"btn"} onClick={showHideMenu}>
                <span />
                <span />
                <span />
            </div>
            <div className={styles.wrapper} ref={idRef} value={-1}>
                {/* <div className={`${styles.btn} ${styles.notActive}`} id={"btn"} onClick={showHideMenu}>
                <span />
                <span />
                <span />
            </div> */}
                <div className={styles.menuContainer} id={"menuContainer"}>
                    <div className={styles.menuSubContainer}>
                        {size.width <= 900 ?
                            <div className={`${styles.btn} ${styles.active}`} id={"btn"} onClick={showHideMenu}>
                                <span />
                                <span />
                                <span />
                            </div>
                            : null
                        }
                        {data ?
                            <div className={styles.itemsWrapper}>
                                {data?.map((item, index) => {
                                    return (
                                        <div key={index} className={styles.itemWrapper}>
                                            <Link onClick={(event) => { if (url.length === 3 && url[2] === stringToUrl(item.name_main)) event.preventDefault(); showHideMenu() }} to={`/${lang}/${stringToUrl(item.name_main)}`} className={`${styles.maniCat} ${url.length === 3 && url[2] === stringToUrl(item.name_main) ? styles.maniCatActive : ''}`}>{item.name}</Link>
                                        </div>)
                                })}
                                {/* <div className={styles.itemWrapper}>
                                <a onClick={(event) => { if (url[2] === stringToUrl(data[0].name)) event.preventDefault(); }} href={`/${lang}/${stringToUrl(data[0].name)}`} className={`${styles.maniCat} ${url[2] === stringToUrl(data[0].name) ? styles.maniCatActive : ''}`}>{data[0].name}</a>
                            </div>
                            <div className={styles.itemWrapper}>
                                <a onClick={(event) => { if (url[2] === stringToUrl(data[1].name)) event.preventDefault(); }} href={`/${lang}/${stringToUrl(data[1].name)}`} className={`${styles.maniCat} ${url[2] === stringToUrl(data[1].name) ? styles.maniCatActive : ''}`}>{data[1].name}</a>
                            </div>
                            <div className={styles.itemWrapper}>
                                <a onClick={(event) => { if (url[2] === stringToUrl(data[2].name)) event.preventDefault(); }} href={`/${lang}/${stringToUrl(data[2].name)}`} className={`${styles.maniCat} ${url[2] === stringToUrl(data[2].name) ? styles.maniCatActive : ''}`}>{data[2].name}</a>
                            </div>
                            <div className={styles.itemWrapper}>
                                <a onClick={(event) => { if (url[2] === stringToUrl(data[3].name)) event.preventDefault(); }} href={`/${lang}/${stringToUrl(data[3].name)}`} className={`${styles.maniCat} ${url[2] === stringToUrl(data[3].name) ? styles.maniCatActive : ''}`}>{data[3].name}</a>
                            </div>
                            <div className={styles.itemWrapper}>
                                <a onClick={(event) => { if (url[2] === stringToUrl(data[4].name)) event.preventDefault(); }} href={`/${lang}/${stringToUrl(data[4].name)}`} className={`${styles.maniCat} ${url[2] === stringToUrl(data[4].name) ? styles.maniCatActive : ''}`}>{data[4].name}</a>
                            </div>
                            <div className={styles.itemWrapper}>
                                <a onClick={(event) => { if (url[2] === stringToUrl(data[5].name)) event.preventDefault(); }} href={`/${lang}/${stringToUrl(data[5].name)}`} className={`${styles.maniCat} ${url[2] === stringToUrl(data[5].name) ? styles.maniCatActive : ''}`}>{data[5].name}</a>
                            </div>
                            */}
                                <div className={styles.itemWrapper}>
                                    <Link onClick={(event) => { if (url[2] === stringToUrl("information")) event.preventDefault(); showHideMenu() }} to={`/${lang}/information`} className={`${styles.maniCat} ${url[2] === 'information' ? styles.maniCatActive : ''}`}>{translationMix(['header','menu','info', '0'])}</Link>
                                </div>
                                <div className={styles.itemWrapper}>
                                    <Link onClick={(event) => { if (url[2] === stringToUrl("blog")) event.preventDefault(); showHideMenu() }} to={`/${lang}/blog`} className={`${styles.maniCat} ${url[2] === 'blog' ? styles.maniCatActive : ''}`}>{translationMix(['header','menu','blog'])}</Link>
                                </div>
                                {size.width <= 450 ?
                                    <>
                                        <p className={styles.title}>{translationMix(['header','account', 'title'])}</p>
                                        {isAuthenticated ? <>
                                            <div className={styles.itemWrapper}>
                                                <Link onClick={(event) => { if (url[2] === stringToUrl("account-user")) event.preventDefault(); showHideMenu() }} to={{ pathname: `/${lang}/account-user`, state: { prevPath: window.location.href } }} className={`${styles.maniCat} ${url[2] === 'account-user' ? styles.maniCatActive : ''}`}>{translationMix(['header','account', 'userData'])}</Link>
                                            </div>
                                            <div className={styles.itemWrapper}>
                                                <Link onClick={(event) => { if (url[2] === stringToUrl("account-address")) event.preventDefault(); showHideMenu() }} to={{ pathname: `/${lang}/account-address`, state: { prevPath: window.location.href } }} className={`${styles.maniCat} ${url[2] === 'account-address' ? styles.maniCatActive : ''}`}>{translationMix(['header','account', 'userAddress'])}</Link>
                                            </div>
                                            <div className={styles.itemWrapper}>
                                                <div onClick={(event) => { logoutFormMobileMenu(); showHideMenu() }} className={`${styles.maniCat}`}>{translationMix(['header','account', 'logout'])}</div>
                                            </div>
                                        </> :
                                            <div className={styles.itemWrapper}>
                                                <Link onClick={(event) => { if (url[2] === stringToUrl("login")) event.preventDefault(); showHideMenu() }} to={{ pathname: `/${lang}/login`, state: { prevPath: window.location.href } }} className={`${styles.maniCat} ${url[2] === 'login' ? styles.maniCatActive : ''}`}>{translationMix(['header','middle', 'right', 'loginText'])}</Link>
                                            </div>}
                                    </> : null}
                            </div>
                            : null}
                        {size.width <= 800 ?
                            <div className={styles.contactWrapper}>
                                <div className={styles.icon} />
                                <div className={styles.phone}>(48) 12 397 77 27</div>
                                <Link onClick={() => { showHideMenu() }} to={`/${lang}/contact`} className={styles.contact}>{translationMix(['header','top','contact'])}</Link>
                            </div>
                            : null
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Menu