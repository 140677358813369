import React from 'react';
import ReactDOM from 'react-dom/client';
import ThemeContextProvider from './Components/Context/context';

import Builder from './Containers/Builder/Builder';

const root = ReactDOM.createRoot(document.getElementById('root'));

/**<React.StrictMode>
 * </React.StrictMode>
 * 
 * Here is React.StrictMode
 * This make Builder refresh only ones
 * Work only in builded version
 * 
 * without
 * Builder refresh twice so every commpomments refresh twice
 * We get double fetch
 */

root.render(
    // <React.StrictMode>
        <ThemeContextProvider>
            <Builder />
        </ThemeContextProvider>
    // </React.StrictMode>
);
