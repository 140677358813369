function ConvertDate(readData, lang = "pl", isCut = false) {
    var options = { month: 'long' };
    var readDate = new Date(readData)
    var endString = ''
    var readMonth = ''
    //en-US

    endString += readDate.getDay() + " "
    if (lang === "pl")
        readMonth = readDate.toLocaleDateString("pl-PL", options)
    else
        readMonth = readDate.toLocaleDateString("en-US", options)
    if (isCut)
        readMonth = readMonth.substring(0, 3);
    endString += readMonth
    endString += ", "
    endString += readDate.getFullYear()
    return (endString)
}

export default ConvertDate