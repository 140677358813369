import React, { useRef } from "react";
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/core';

import styles from './blogSlider.module.scss'
import './blogSlider.scss' // nie czyta module.scss is-active

function BlogSlider(props) {

    const splide = useRef()

    const startAutoplay = () => {
        splide.current.splide.Components.Autoplay.play()
    }

    const stopAutoplay = () => {
        splide.current.splide.Components.Autoplay.pause()
    }

    return (
        <Splide hasTrack={ false } aria-label="..." className={styles.carousel} ref={splide} onMouseEnter={stopAutoplay} onMouseLeave={startAutoplay}
                options={{
                    type: 'fade',
                    perMove: 1,
                    autoplay: false,
                    interval: 5000,
                    pagination: true,
                    drag: true,
                    speed: 500,
                    rewind: true,
                    arrows: false,
                    classes : {
                        pagination: `splide__pagination ${styles.pagination}`, // container
                        page      : `splide__pagination__page paginationButton`, // each button
                    }
                }}
        >
            <SplideTrack>
                {props.children?.map((item, index) => {
                    return (
                        <SplideSlide key={index}>
                            {item}
                        </SplideSlide>
                    )
                })}
            </SplideTrack>
        </Splide>
    )
}

export default BlogSlider