import React, { useContext, useEffect, useState } from "react";
import { Loader } from "../../../Components/Loader/loader";
import styles from './userOrder.module.scss'
import { getUserOrders } from "../../../apiCalls/productApiCalls";
import { translationMix } from "../../../Components/Helpers/translationMix";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ThemeContext } from "../../../Components/Context/context";
import { Helmet } from "react-helmet";

function UserOrder() {
    const [ordersList, setOrdersList] = useState(false);
    const globalContext = useContext(ThemeContext)
    const { lang } = useParams();

    useEffect(() => {
        if (globalContext.authToken) {
            getUserOrders(globalContext.authToken).then(json => {
                setOrdersList(json.result)
            })
        }
    }, [globalContext.authToken])

    return (
        <>
            <Helmet>
                <title>{lang === 'pl' ? 'Bizserver - Lista Zamówień' : lang === 'en' ? 'Bizserver - Order List' : lang === 'de' ? 'Bizserver - Bestellliste' : 'Bizserver - Seznam objednávek'}</title>
                <meta name="description" content={lang === 'pl' ? "Bizserver - Tu znajdziesz listę swoich zamówień" : lang === 'en' ? 'Bizserver - Here you will find a list of your orders' : lang === 'de' ? 'Bizserver - Hier finden Sie eine Liste Ihrer Bestellungen' : 'Bizserver - Zde najdete seznam vašich objednávek'} />
            </Helmet>
            <Loader justLoad={true} />
            <div className={styles.container}>
                <div className={styles.mainContainer}>
                    <div className={styles.list}>
                        {ordersList ? ordersList.map((item, index) => {
                            return (
                                <div key={index} className={styles.element}>
                                    <div className={styles.table}>
                                        <div className={styles.tableRow}>
                                            <div className={`${styles.cell} ${styles.cell1}`}>
                                                <div className={`${styles.cellBold}`}>
                                                    {translationMix(['account', 'userOrder', 'orderNumber'])}
                                                </div>
                                                <div className={`${styles.cellNormal}`}>
                                                    {item.reference}
                                                </div>
                                            </div>
                                            <div className={`${styles.cell} ${styles.cell2}`}>
                                                <div className={`${styles.cellBold}`}>
                                                    {translationMix(['account', 'userOrder', 'date'])}
                                                </div>
                                                <div className={`${styles.cellNormal}`}>
                                                    {item.created_at}
                                                </div>
                                            </div>
                                            <div className={`${styles.cell} ${styles.cell3}`}>
                                                <div className={`${styles.cellBold}`}>
                                                    {translationMix(['account', 'userOrder', 'price'])}
                                                </div>
                                                <div className={`${styles.cellNormal}`}>
                                                    {item.price_products_total}
                                                </div>
                                            </div>
                                            <div className={`${styles.cell} ${styles.cell4}`}>
                                                <div className={`${styles.cellBold}`}>
                                                    {translationMix(['account', 'userOrder', 'status'])}
                                                </div>
                                                <div className={`${styles.cellNormal}`}>
                                                    {item.status}
                                                </div>
                                            </div>
                                            <div className={`${styles.cell} ${styles.cell5}`}>
                                                <div className={`${styles.cellBold}`}>
                                                    {translationMix(['account', 'userOrder', 'options'])}
                                                </div>
                                                <div className={`${styles.cellCenter}`}>
                                                    <Link to={`/${lang}/account-order/${item.id}`} className={styles.btn}>{translationMix(['account', 'userOrder', 'button', '0'])}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }) : null}
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserOrder;