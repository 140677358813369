export const apiUrls = {
    apiKey : {
        pl : "?apiKey=af74de6dcab0d64fb5864909c2da7bb6"
    },
    mainUrl2 : "https://api.servermarket.eu/",
    mainUrl : "/api/",
    global: "main/global",
    menu: "main/menu",
    countires: "customers/get-countries",
    cart: "cart/load",
    homepage : "main/homepage",
    products : "products/get-all-products",
    categoryProducts: "products/search?idMenu=",
    product : "products/get-product",
    blog : "posts/get-main-page",
    seachPost: "posts/search-posts",
    post: ["posts/get?id=","&short=0&other=1&comments=1"]
};