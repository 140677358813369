import React, { useEffect, useRef, useState } from "react";
import styles from "./cart.module.scss"
import useWindowSize from "../../../Components/Helpers/useWindowSize";
import { updateProductCart, removeProducFromCart } from "../../../apiCalls/productApiCalls";
import numberWithSpace from "../../../Components/Helpers/numberWithSpace";
import LoaderAnimation from "../../../Components/LoaderAnimation/loaderAnimation";
import { Link, useParams } from "react-router-dom";
import stringToUrl from "../../../Components/Helpers/stringToUrl";
import { translationMix } from "../../../Components/Helpers/translationMix";

function Cart({ setOption, setCartData, cartData, isLoadR = false }) {
    const [isLoad, setIsLoad] = useState(isLoadR)
    const [products, setProducts] = useState(null)
    const [totalPrice, setTotalPrice] = useState(null)
    const [currency, setCurrency] = useState('zł')
    const { lang } = useParams()
    const coverRef = useRef(null)
    const size = useWindowSize()

    function goPrevPage() {
        window.location.href = window.history.state.state.prevPath;
    }

    function changeQuantity(id, max, value) {
        const readCounter = document.querySelector(`[data-counter~="${id}"]`)
        if (readCounter) {
            let readValue = Number(readCounter.value)
            if (value > 0) {
                if (readValue + 1 <= max)
                    readCounter.value = readValue + 1
            } else if (value < 0) {
                if (readValue - 1 > 0)
                    readCounter.value = readValue - 1
            } else {
                if (readValue > max) {
                    readValue = max;
                    readCounter.value = max;
                }
            }
            if (readValue + value <= max && readValue + value > 0)
                updateProductCart(id, readValue + value).then(json => {
                    setCurrency(json.cart.currency)
                    setProducts(json.cart.products)
                    const jsonTmp = { price_delivery: json.cart.price_delivery, price_products_total: json.cart.price_products_total, price_total: json.cart.price_total }
                    setTotalPrice(jsonTmp)
                    setCartData(json.cart)
                })
        }
    }

    function removeProduct(id) {
        if (window.confirm(translationMix(['cart','cart','delete']))) {
            removeProducFromCart(id).then((json) => {


                if (json.cart.products.length === 0) {
                    setIsLoad(true)
                    setTimeout(() => {
                        if (coverRef.current)
                            coverRef.current.classList.remove(styles.opacityFull)
                        setTimeout(() => {
                            if (coverRef.current)
                                coverRef.current.classList.remove(styles.coverShow)
                            setIsLoad(false)
                        }, 800)
                    }, 200)
                    setCurrency(json.cart.currency)
                    setProducts(json.cart.products)
                    const jsonTmp = { price_delivery: json.cart.price_delivery, price_products_total: json.cart.price_products_total, price_total: json.cart.price_total }
                    setTotalPrice(jsonTmp)
                    setCartData(json.cart)
                } else {

                    const item = document.querySelector(`[data-caritem~="${id}"]`)
                    item.style.transition = "0.3s all"
                    item.style.height = '0px'

                    setTimeout(() => {
                        item.style.transition = "0s all"
                        item.style.height = size.width > 1100 ? '140px' : '320px'
                        
                        setCurrency(json.cart.currency)
                        setProducts(json.cart.products)
                        const jsonTmp = { price_delivery: json.cart.price_delivery, price_products_total: json.cart.price_products_total, price_total: json.cart.price_total }
                        setTotalPrice(jsonTmp)
                        setCartData(json.cart)
                        // item.style.height = '140px'
                    }, 300)
                }
            })
        }
    }

    useEffect(() => {
        if (coverRef.current) {
            coverRef.current.classList.add(styles.coverShow)
            setTimeout(() => {
                coverRef.current.classList.add(styles.opacityFull)
            }, 1)
        }
        if(cartData){
            setCurrency(cartData.currency)
            setProducts(cartData.products)
            const jsonTmp = { price_delivery: cartData.price_delivery, price_products_total: cartData.price_products_total, price_total: cartData.price_total }
            setTotalPrice(jsonTmp)
        }
    }, [cartData]);

    useEffect(() => {
        if (products) {
            if (isLoad && coverRef.current) {
                setTimeout(() => {
                    if (coverRef.current)
                        coverRef.current.classList.remove(styles.opacityFull)
                    setTimeout(() => {
                        if (coverRef.current)
                            coverRef.current.classList.remove(styles.coverShow)
                        setIsLoad(false)
                    }, 800)
                }, 200)
            }
        }
    }, [products, isLoad])

    return (
        <div className={styles.container}>
            <div className={styles.main}>
                {isLoad ? <>
                    <div ref={coverRef} className={`${styles.cover} ${styles.coverShow} ${styles.opacityFull}`}>
                        {size.width > 1200 ? <LoaderAnimation width={250} height={250} /> : size.width > 600 ? <LoaderAnimation width={180} height={180} /> : <LoaderAnimation width={130} height={130} />}
                    </div>
                </> : null}
                <div className={styles.title}>{products ? products.length > 0 ? `${translationMix(['cart','cart','titles','0'])}:` : translationMix(['cart','cart','titles','1']) : translationMix(['cart','cart','titles','1'])}</div>
                {products ? products.length > 0 ? <>
                    <div className={styles.labelContainer}>
                        <div className={styles.labelText}>{translationMix(['cart','cart','labels','0'])}</div>
                        <div className={styles.labelText}>{translationMix(['cart','cart','labels','1'])}</div>
                        <div className={styles.labelText}>{translationMix(['cart','cart','labels','2'])}</div>
                        <div className={styles.labelText}>{translationMix(['cart','cart','labels','3'])}</div>
                    </div>
                    <div className={styles.tableContainer}>
                        {products.map((readProduct, index) => {
                            return (
                                <div className={styles.item} key={index} data-caritem={readProduct.id_product}>
                                    <Link to={`/${lang}/product/${readProduct.id_product}-${stringToUrl(readProduct.name)}`}>
                                    <div className={styles.icon} style={{ backgroundImage: `url("${readProduct.image}")` }}/>
                                    </Link>
                                    <div className={styles.descriptionContainer}>
                                        <div className={styles.text}>{readProduct.name}</div>
                                        <div className={styles.ultraSmallText}>SKU: {readProduct.reference}</div>
                                        <div className={styles.amountSmall}>{translationMix(['shared','amount','1'])}</div>
                                    </div>
                                    <div className={styles.price}>{numberWithSpace(readProduct.price_gross)} {currency}</div>
                                    <div className={styles.counterContainer}>
                                        <div className={styles.btnContainer}>
                                            {readProduct.quantity > 1 ? <div className={styles.btnValue} onClick={() => { changeQuantity(readProduct.id_product, readProduct.max_quantity, -1) }}>-</div> : null}
                                        </div>
                                        {/* <div className={styles.counter} data-counter={readProduct.id_product}><div className={sharedStyles.textStrong}>{readProduct.quantity}</div></div> */}
                                        {/* onChange={changeQuantity(readProduct.id_product, readProduct.max_quantity, 0)} */}
                                        <input className={`${styles.counter} ${styles.textStrong}`} data-counter={readProduct.id_product} defaultValue={readProduct.quantity}
                                            onChange={(event) => { event.target.value = event.target.value.replace(/\D/, '') }}
                                            onBlur={(event) => { if (event.target.value <= 0) event.target.value = 1; changeQuantity(readProduct.id_product, readProduct.max_quantity, 0) }}
                                        ></input>
                                        <div className={styles.btnContainer}>
                                            {Number(readProduct.quantity) < Number(readProduct.max_quantity) ? <div className={styles.btnValue} onClick={() => { changeQuantity(readProduct.id_product, readProduct.max_quantity, 1) }}>+</div> : null}
                                        </div></div>
                                    <div className={styles.btnTrash} onClick={() => { removeProduct(readProduct.id_product) }} />
                                    <div className={styles.totalPrice}>{numberWithSpace(readProduct.price_total)} {currency}</div>
                                </div>
                            )
                        })}

                    </div> </> : null : null}
                <div className={styles.bottom}>
                    {size.width > 830 ? 
                    <div className={styles.left}>
                        {products ? products.length > 0 ? <div className={styles.placeForCode}>
                            {/* <p className={sharedStyles.text2}>{translation.cart.cart.code[0]}</p>
                                <input className={styles.input} placeholder={translation.cart.cart.code[1]}></input> */}
                        </div> : null : null}
                        {size.width <= 830 ? null :
                            <div className={styles.btnPrev} onClick={() => goPrevPage()}><p>{translationMix(['cart','form','btnBack','0'])}</p><p>{translationMix(['cart','form','btnBack','1'])}</p></div>}
                    </div> : null }
                    {products ? products.length > 0 ?
                        <div className={styles.right}>
                            <div className={styles.top}>
                                <div className={styles.columnLeft}>
                                    <p className={styles.text1}>{translationMix(['cart','cart','summary','0'])}< br />{translationMix(['cart','cart','summary','1'])}</p>
                                    {totalPrice ? totalPrice.price_delivery > 0 ? <p className={styles.text2}>{translationMix(['cart','cart','summary','2'])}</p> : null : null}
                                    <p className={styles.text3}>{translationMix(['cart','cart','summary','3'])}:</p>
                                </div>
                                <div className={styles.columnRight}>
                                    <p className={styles.text1}>{totalPrice ? totalPrice.price_products_total : null} {currency ? currency : null}</p>
                                    {totalPrice ? totalPrice.price_delivery > 0 ? <p className={styles.text2}>od {totalPrice ? totalPrice.price_delivery : null} {currency ? currency : null}</p> : null : null}
                                    <p className={styles.text3}>{totalPrice ? totalPrice.price_total : null} {currency ? currency : null}</p>
                                </div>
                            </div>
                            {size.width <= 830 ?
                                <div className={styles.top}>
                                    <div className={styles.columnLeft}><div className={styles.btnPrev} onClick={() => goPrevPage()}><p>{translationMix(['cart','form','btnBack','0'])}</p><p>{translationMix(['cart','form','btnBack','1'])}</p></div></div>
                                    <div className={styles.columnRight}><div className={styles.btnGrey} onClick={() => { setOption(1) }}>{translationMix(['cart','cart','btn'])}</div></div>
                                </div>
                                : <div className={styles.btnGrey} onClick={() => { setOption(1) }}>{translationMix(['cart','cart','btn'])}</div>}
                        </div> : null : null}
                </div>
            </div>
        </div>
    )
}

export default Cart;