import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/core';

import styles from './gallery.module.scss'

function Thumbnail({ data, mainRef, forwardRef, slideActive }) {

    const goToSlide = (event, index) => {
        mainRef.current.go(index)
    }
    return (
        <Splide hasTrack={false} aria-label="..." className={styles.carousel} ref={forwardRef}
            options={{
                perPage: 4,
                cover: true,
                gap: '10px',
                pagination: false, //te kropki na dole
                breakpoints:{
                    580 : {
                        perPage: 3
                    },
                    350 : {
                        perPage: 2
                    }
                }
            }}
        >
            <div className="splide__arrows">
                <button className={`splide__arrow splide__arrow--prev ${styles.arrow} ${styles.leftArrowThum}`} />
                <button className={`splide__arrow splide__arrow--next ${styles.arrow} ${styles.rightArrowThum}`} />
            </div>
            <SplideTrack className={styles.thum}>
                {data.map((item, index) => {
                    return (
                        <SplideSlide id={index} key={index} className={styles.item} onClick={(event) => { goToSlide(event, index) }} >
                            <div className={`${styles.img2} ${index === slideActive ? `${styles.itemActive}` : null}`} style={{ backgroundImage: `url("${item.image_url}")` }} />
                        </SplideSlide>
                    );
                })}
            </SplideTrack>
        </Splide>
    )
}

export default Thumbnail