import styles from './loaderAnimation.module.scss'

export function LoaderAnimation(props) {
    return (
        <div className={styles.loaderInner} style={{ width: props.width, height: props.height }}>
            {/* <div className={styles.globe}></div> */}
            <div className={styles.one} style={{ width: props.width, height: props.height }}></div>
            {props.isOne ? null : <div className={styles.two} style={{ width: props.width, height: props.height }}></div>}
        </div>
    );
}

export default LoaderAnimation;