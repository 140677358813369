import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { sendNewPassword } from "../../../apiCalls/authApiCalls";
import { translationMix } from "../../../Components/Helpers/translationMix";
import styles from "./resetPassword.module.scss"

function ResetPassword() {
    const [isDone, setIsDone] = useState(null);
    const passwordErrorRef = useRef(null)
    const password1Ref = useRef(null)
    const password2Ref = useRef(null)
    const { lang } = useParams()

    function hideLabel(event, index) {
        const readInput = document.querySelector(`[data-label~="${index}"]`)
        if (event.target.value) {
            if (!readInput.classList.contains(styles.hide))
                readInput.classList.add(styles.hide)
        } else {
            readInput.classList.remove(styles.hide)
        }
    }

    function showHideOkMin(event, index) {
        const readOk = document.querySelector(`[data-ok~="${index}"]`)
        if (event.target.value) {
            event.target.parentElement.classList.remove(styles.borderRed)
            if (event.target.value.length > 3) {
                if (!readOk.classList.contains(styles.iconShow))
                    readOk.classList.add(styles.iconShow)
            } else
                readOk.classList.remove(styles.iconShow)
        } else
            readOk.classList.remove(styles.iconShow)
    }

    function validatePasswords() {
        if (password1Ref.current.value.length > 3) {
            if (password1Ref.current.value === password2Ref.current.value) {
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                let readURLToken = urlParams.get('token');
                // console.log(password1Ref.current.value)
                // console.log(password2Ref.current.value)
                // console.log(readURLToken)

                sendNewPassword(password1Ref.current.value, password2Ref.current.value, readURLToken).then(res => {
                    if (res) {
                        if (res.status) {
                            if (res.status === true) {
                                setIsDone(true);
                            } else
                                setIsDone(false);
                        } else
                            setIsDone(false);
                    } else
                        setIsDone(false);
                })

            } else {
                passwordErrorRef.current.classList.add(styles.red)
                passwordErrorRef.current.innerText = translationMix(['account', 'passwordReset', 'error', '2'])
                setTimeout(() => {
                    passwordErrorRef.current.classList.remove(styles.red)
                }, 3000)
            }
        }
        else {
            passwordErrorRef.current.classList.add(styles.red)
            passwordErrorRef.current.innerText = translationMix(['account', 'passwordReset', 'error', '3'])
            setTimeout(() => {
                passwordErrorRef.current.classList.remove(styles.red)
            }, 3000)
        }
        return false;
    }

    return (<>
        <Helmet>
            <title>{lang === 'pl' ? 'Bizserver - Zapomniałem hasła' : lang === 'en' ? 'Bizserver - I forgot my password' : 'Bizserver - Ich habe mein Passwort vergessen'}</title>
            <meta name="description" content={"Bizserver - Aktywacja konta"} />
        </Helmet>
        {isDone === null ?
            <div className={`${styles.container}`}>
                <div className={styles.mainContainer}>
                    <div className={styles.main}>
                        <form autoComplete={"off"} noValidate onSubmit={(event) => { event.preventDefault(); validatePasswords(); }} className={`${styles.vertical} ${styles.maxHeight}`}>
                            <div className={`${styles.horizontal} ${styles.center}`}>
                                <div className={`${styles.vertical} ${styles.myLimitWidht}`}>
                                    <div className={`${styles.error}`} ref={passwordErrorRef}></div>
                                    <div className={styles.text1}>{translationMix(['account', 'passwordReset', 'label', '0'])}</div>
                                    <div className={styles.inputContainer}>
                                        <input ref={password1Ref} className={styles.input} type={"password"} required onInput={(event) => { hideLabel(event, 11); showHideOkMin(event, 11); }} />
                                        <div className={styles.labelContainer}>
                                            <div className={styles.textReq} data-label={11}>{translationMix(['account', 'passwordReset', 'placeholder'])}</div>
                                        </div>
                                        <div className={styles.iconOk} data-ok={11} />
                                    </div>
                                    <div className={styles.text1}>{translationMix(['account', 'passwordReset', 'label', '1'])}</div>
                                    <div className={styles.inputContainer}>
                                        <input ref={password2Ref} className={styles.input} type={"password"} required onInput={(event) => { hideLabel(event, 12); showHideOkMin(event, 12); }} />
                                        <div className={styles.labelContainer}>
                                            <div className={styles.textReq} data-label={12}>{translationMix(['account', 'passwordReset', 'placeholder'])}</div>
                                        </div>
                                        <div className={styles.iconOk} data-ok={12} />
                                    </div>
                                </div>
                            </div>
                            <button type={"submit"} className={`${styles.btn} ${styles.right}`} >{translationMix(['account', 'passwordReset', 'btn'])}</button>
                        </form>
                    </div>
                </div>
            </div> :
            isDone === true ?
                <div className={styles.endText}>{translationMix(['account', 'passwordReset', 'info3'])}</div> :
                <div className={styles.endText}>{translationMix(['account', 'passwordReset', 'info2'])}</div>
        }
    </>
    )

}

export default ResetPassword;