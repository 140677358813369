import React, { createRef, useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { checkMailInDB } from "../../../apiCalls/authApiCalls";
import { getUserData, updateUserData, updateUserPassword } from "../../../apiCalls/userApiCalls";
import { ThemeContext } from "../../../Components/Context/context";
import { validateMailEvent, validateMailRef } from "../../../Components/Helpers/mailValidation";
import { validatePhoneRef } from "../../../Components/Helpers/phoneValidation";
import { translationMix } from "../../../Components/Helpers/translationMix";
import { Loader } from "../../../Components/Loader/loader";
import LoaderAnimation from "../../../Components/LoaderAnimation/loaderAnimation";
import styles from './userData.module.scss';


function UserData() {
    const [userData, setUserData] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingPassword, setIsLoadingPassword] = useState(false);
    const [errorTimeOut, setErrorTimeOut] = useState([])
    const [errorPasswordTimeOut, setErrorPasswordTimeOut] = useState([])
    const mailError = useRef(null)
    const passwordError = useRef(null)
    const inputsRefs = useRef([])
    const { lang } = useParams()
    const globalContext = useContext(ThemeContext)

    const arrLength = 14
    if (inputsRefs.current.length !== arrLength) {
        // add or remove refs
        inputsRefs.current = Array(arrLength)
            .fill()
            .map((_, i) => inputsRefs.current[i] || createRef());
    }

    function uncheckBox(index) {
        if (inputsRefs.current[index].current.checked)
            inputsRefs.current[index].current.checked = false;
    }

    function hideLabel(event, index) {
        const readInput = document.querySelector(`[data-label~="${index}"]`)
        if (event.target.value) {
            if (!readInput.classList.contains(styles.hide))
                readInput.classList.add(styles.hide)
        } else {
            readInput.classList.remove(styles.hide)
        }
    }

    function hideLabelByIndex(index) {
        const readLabel = document.querySelector(`[data-label~="${index}"]`)
        if (!readLabel.classList.contains(styles.hide))
            readLabel.classList.add(styles.hide)
    }

    function showLabelByIndex(index) {
        const readLabel = document.querySelector(`[data-label~="${index}"]`)
        if (readLabel)
            readLabel.classList.remove(styles.hide)
    }

    function showHideOkMail(event, index) {
        const readOk = document.querySelector(`[data-ok~="${index}"]`)
        if (validateMailEvent(event)) {
            if (!readOk.classList.contains(styles.iconShow))
                readOk.classList.add(styles.iconShow)
            event.target.parentElement.classList.remove(styles.borderRed)
        } else
            readOk.classList.remove(styles.iconShow)
    }

    function showHideOkReq(event, index) {
        const readOk = document.querySelector(`[data-ok~="${index}"]`)
        if (event.target.value) {
            if (!readOk.classList.contains(styles.iconShow))
                readOk.classList.add(styles.iconShow)
            event.target.parentElement.classList.remove(styles.borderRed)
        } else
            readOk.classList.remove(styles.iconShow)
    }

    function showHideOkById(index) {
        const readOk = document.querySelector(`[data-ok~="${index}"]`)
        if (!readOk.classList.contains(styles.iconShow))
            readOk.classList.add(styles.iconShow)
    }

    function hideOkById(index) {
        const readOk = document.querySelector(`[data-ok~="${index}"]`)
        if (readOk)
            readOk.classList.remove(styles.iconShow)
    }

    function validateData(arrayTextError = []) {
        //check required
        let valid = true;
        for (let i = 0; i < 5; i++) {
            if (i === 0) {
                if (!validateMailRef(inputsRefs.current[i])) {
                    if (!inputsRefs.current[i].current.parentElement.classList.contains(styles.borderRed))
                        inputsRefs.current[i].current.parentElement.classList.add(styles.borderRed)
                    valid = false
                } else {
                    inputsRefs.current[i].current.parentElement.classList.remove(styles.borderRed)
                }
            } else if (i === 5) {
                if (!validatePhoneRef(inputsRefs.current[i])) {
                    inputsRefs.current[i].current.parentElement.classList.add(styles.borderRed)
                    arrayTextError.push(translationMix(['account', 'userData', 'error','0']))
                    valid = false
                }
                else {
                    inputsRefs.current[i].current.parentElement.classList.remove(styles.borderRed)
                }
            }
            else if (inputsRefs.current[i].current.required) {
                if (i === 13) {
                    if (!inputsRefs.current[i].current.checked) {
                        valid = false;
                        if (!inputsRefs.current[i].current.classList.contains(styles.colorRed))
                            inputsRefs.current[i].current.classList.add(styles.colorRed)
                    }
                }
                if (!inputsRefs.current[i].current.value) {
                    valid = false;
                    if (!inputsRefs.current[i].current.parentElement.classList.contains(styles.borderRed))
                        inputsRefs.current[i].current.parentElement.classList.add(styles.borderRed)
                } else if (i !== 9) {
                    inputsRefs.current[i].current.parentElement.classList.remove(styles.borderRed)
                }
            }
        }
        errorTimeOut.forEach(element => {
            clearTimeout(element)
        });
        mailError.current.innerText = arrayTextError.join(", ")
        mailError.current.classList.add(styles.red)
        const myTimeOut1 = setTimeout(() => {
            mailError.current.classList.remove(styles.red)
        }, 3000)
        const myTimeOut2 = setTimeout(() => {
            mailError.current.innerText = ''
        }, 3300)
        setErrorTimeOut([myTimeOut1, myTimeOut2])
        return valid;
    }

    function validateUserData() {
        let tmpArrayEndErrorText = []
        if (inputsRefs.current[0].current.value.length > 0) {
            setIsLoading(true)
            setTimeout(() => {
                if (inputsRefs.current[0].current.value === userData.email) {
                    if (validateData()) {
                        updateUserDataInDb();
                        return true;
                    } else {
                        setIsLoading(false)
                        return false;
                    }
                } else {
                    checkMailInDB(inputsRefs.current[0].current.value).then(check => {
                        //check - mail is in DB
                        // console.log(userData)
                        if (check) {
                            if (!inputsRefs.current[0].current.parentElement.classList.contains(styles.borderRed))
                                inputsRefs.current[0].current.parentElement.classList.add(styles.borderRed)
                            tmpArrayEndErrorText.push(translationMix(['account', 'userData', 'error','1']))
                            validateData(tmpArrayEndErrorText)
                            setIsLoading(false)
                        } else {
                            if (validateData()) {
                                updateUserDataInDb()
                                return true;
                            } else {
                                setIsLoading(false)
                                return false;
                            }
                        }
                    })
                }
            }, 100)

        }
        validateData()
        return false;
    }

    function getAllData() {
        if (validateData()) {
            let genderValue = 'null'
            if (inputsRefs.current[1].current.checked)
                genderValue = 'male'
            else if (inputsRefs.current[2].current.checked)
                genderValue = 'female'
            const myJsonData = {
                email: inputsRefs.current[0].current.value,
                gender: genderValue,
                firstname: inputsRefs.current[3].current.value,
                lastname: inputsRefs.current[4].current.value,
                phone: inputsRefs.current[5].current.value,
            }
            return myJsonData
        }
        else
            return JSON.parse(null)
    }

    function updateUserDataInDb() {
        updateUserData(getAllData(), globalContext.authToken).then(res => {
            let endInfo = ''
            if (res.status) {
                if (res.status === true) {
                    endInfo = translationMix(['account', 'userData', 'error','2'])
                } else {
                    endInfo = translationMix(['account', 'userData', 'error','3'])
                }
            } else {
                endInfo = translationMix(['account', 'userData', 'error','3'])
            }
            errorTimeOut.forEach(element => {
                clearTimeout(element)
            });
            mailError.current.innerText = endInfo
            mailError.current.classList.add(styles.red)
            setTimeout(() => {
                mailError.current.classList.add(styles.opacityFull)
            }, 300)
            const myTimeOut1 = setTimeout(() => {
                if (mailError.current) {
                    mailError.current.classList.remove(styles.red)
                    mailError.current.classList.remove(styles.opacityFull)
                }
            }, 3000)
            const myTimeOut2 = setTimeout(() => {
                mailError.current.innerText = ''
            }, 3300)
            setErrorTimeOut([myTimeOut1, myTimeOut2])
            setIsLoading(false)

        })
    }

    function validateUserPassword() {
        setIsLoadingPassword(true)
        let valid = true;
        let endInfo = ''
        inputsRefs.current[6].current.parentElement.classList.remove(styles.borderRed)
        inputsRefs.current[7].current.parentElement.classList.remove(styles.borderRed)
        inputsRefs.current[8].current.parentElement.classList.remove(styles.borderRed)
        setTimeout(() => {
            if (inputsRefs.current[6].current.value.length > 0) {
                if (inputsRefs.current[7].current.value.length >= 4) {
                    if (inputsRefs.current[7].current.value === inputsRefs.current[8].current.value) {
                        valid = true;
                        const data = { password: String(inputsRefs.current[6].current.value), new_password: String(inputsRefs.current[7].current.value), new_password2: String(inputsRefs.current[8].current.value) };
                        updateUserPassword(data, globalContext.authToken).then(res => {
                            if (res.status) {
                                if (res.status === 'noValid') {
                                    endInfo = translationMix(['account', 'userData', 'error','4'])
                                    valid = false;
                                } else if (res.status === false) {
                                    endInfo = translationMix(['account', 'userData', 'error','5'])
                                    valid = false;
                                } else if (res.status === true) {
                                    endInfo = translationMix(['account', 'userData', 'error','6'])
                                    inputsRefs.current[6].current.value = null;
                                    inputsRefs.current[7].current.value = null;
                                    inputsRefs.current[8].current.value = null;
                                    showLabelByIndex(4);
                                    hideOkById(4);
                                    showLabelByIndex(5);
                                    hideOkById(5);
                                    showLabelByIndex(6);
                                    hideOkById(6);
                                    valid = true;
                                }
                            }
                            errorPasswordTimeOut.forEach(element => {
                                clearTimeout(element)
                            });
                            passwordError.current.innerText = endInfo
                        })
                    } else {
                        if (!inputsRefs.current[7].current.parentElement.classList.contains(styles.borderRed))
                            inputsRefs.current[7].current.parentElement.classList.add(styles.borderRed)
                        if (!inputsRefs.current[8].current.parentElement.classList.contains(styles.borderRed))
                            inputsRefs.current[8].current.parentElement.classList.add(styles.borderRed)
                        endInfo = translationMix(['account', 'userData', 'error','7'])
                        valid = false;
                    }
                } else {
                    if (!inputsRefs.current[7].current.parentElement.classList.contains(styles.borderRed))
                        inputsRefs.current[7].current.parentElement.classList.add(styles.borderRed)
                    endInfo = translationMix(['account', 'userData', 'error','8'])
                    valid = false;
                }

            } else {
                if (!inputsRefs.current[6].current.parentElement.classList.contains(styles.borderRed))
                    inputsRefs.current[6].current.parentElement.classList.add(styles.borderRed)
                endInfo = translationMix(['account', 'userData', 'error','9'])
                valid = false;
            }

            errorPasswordTimeOut.forEach(element => {
                clearTimeout(element)
            });
            if (endInfo.length > 0)
                passwordError.current.innerText = endInfo
            passwordError.current.classList.add(styles.red)
            setTimeout(() => {
                passwordError.current.classList.add(styles.opacityFull)
            }, 300)
            const myTimeOut1 = setTimeout(() => {
                if (passwordError.current) {
                    passwordError.current.classList.remove(styles.red)
                    passwordError.current.classList.remove(styles.opacityFull)
                }
            }, 3000)
            const myTimeOut2 = setTimeout(() => {
                if (passwordError.current)
                    passwordError.current.innerText = ''
            }, 3300)
            setErrorPasswordTimeOut([myTimeOut1, myTimeOut2])
            setIsLoadingPassword(false)
        }, 500)
        return valid;
    }

    useEffect(() => {
        getUserData(globalContext.authToken).then(res => {
            setUserData(res)
        })
    }, [globalContext.authToken])

    useEffect(() => {
        if (userData) {
            inputsRefs.current[0].current.value = userData.email
            inputsRefs.current[1].current.defaultChecked = userData.gender === "male" ? true : false
            inputsRefs.current[2].current.defaultChecked = userData.gender === "female" ? true : false
            inputsRefs.current[3].current.value = userData.firstname
            inputsRefs.current[4].current.value = userData.lastname
            inputsRefs.current[5].current.value = userData.phone
            for (let i = 0; i <= 3; i++) {
                hideLabelByIndex(i)
                showHideOkById(i)
            }
        }
    }, [userData])

    return (
        <>
        <Helmet>
            <title>{lang === 'pl' ? 'Bizserver - Twoje konto' : lang === 'en' ? 'Bizserver - Your account' : 'Bizserver - Ihr Konto'}</title>
            <meta name="description" content={"Bizserver - Twoje konto"} />
        </Helmet>
            <Loader justLoad={true} />
            <div className={styles.container}>
                <div className={styles.mainContainer}>
                    <div className={styles.main}>
                        <div className={styles.vertical}>
                            <div className={styles.title}>{translationMix(['account', 'userData', 'title'])}</div>
                            <div className={styles.horizontalMain} style={{ marginBottom: "20px" }}>
                                <form autoComplete={"off"} noValidate onSubmit={(event) => { event.preventDefault(); validateUserData() }} className={`${styles.verticalLeft}`}>
                                    <div className={styles.text1}>{translationMix(['account', 'create', 'data'])}</div>
                                    <div className={`${styles.error}`} ref={mailError}></div>
                                    <div className={styles.inputContainer}>
                                        <input ref={inputsRefs.current[0]} className={styles.input} type={"email"} required onInput={(event) => { hideLabel(event, 0); showHideOkMail(event, 0); }} />
                                        <div className={styles.labelContainer}>
                                            <div className={styles.textReq} data-label={0}>{translationMix(['account', 'create', 'lables', '0'])}</div>
                                        </div>
                                        <div className={styles.iconOk} data-ok={0} />
                                    </div>
                                    <div className={`${styles.horizontalCenter} ${styles.paddingSmall}`} style={{ minHeight: "40px" }}>
                                        <div className={styles.text2} style={{ marginRight: "15px" }}>{translationMix(['account', 'create', 'gender', '0'])}</div>
                                        <input ref={inputsRefs.current[1]} type={"checkbox"} className={styles.checkboxRound} onClick={() => uncheckBox(2)}></input>
                                        <div className={styles.text2} style={{ marginRight: "10px" }}>{translationMix(['account', 'create', 'gender', '1'])}</div>
                                        <input ref={inputsRefs.current[2]} type={"checkbox"} className={styles.checkboxRound} onClick={() => uncheckBox(1)}></input>
                                        <div className={styles.text2}>{translationMix(['account', 'create', 'gender', '2'])}</div>
                                    </div>
                                    <div className={styles.inputContainer}>
                                        <input ref={inputsRefs.current[3]} className={styles.input} type={"text"} required onInput={(event) => { hideLabel(event, 1); showHideOkReq(event, 1); }} />
                                        <div className={styles.labelContainer}>
                                            <div className={styles.textReq} data-label={1}>{translationMix(['account', 'create', 'lables', '1'])}</div>
                                        </div>
                                        <div className={styles.iconOk} data-ok={1} />
                                    </div>
                                    <div className={styles.inputContainer}>
                                        <input ref={inputsRefs.current[4]} className={styles.input} type={"text"} required onInput={(event) => { hideLabel(event, 2); showHideOkReq(event, 2); }} />
                                        <div className={styles.labelContainer}>
                                            <div className={styles.textReq} data-label={2}>{translationMix(['account', 'create', 'lables', '2'])}</div>
                                        </div>
                                        <div className={styles.iconOk} data-ok={2} />
                                    </div>
                                    <div className={styles.inputContainer}>
                                        <input ref={inputsRefs.current[5]} className={styles.input} type={"tel"} required defaultValue={userData.phone}
                                            onInput={(event) => { hideLabel(event, 3); showHideOkReq(event, 3); }}
                                        // onChange={(event) => {  event.target.value = event.target.value.replace(/\D/, '') }}
                                        />
                                        <div className={styles.labelContainer}>
                                            <div className={styles.textReq} data-label={3}>{translationMix(['account', 'create', 'lables', '3'])}</div>
                                        </div>
                                        <div className={styles.iconOk} data-ok={3} />
                                    </div>
                                    <button type={"submit"} className={styles.btn}>
                                        {isLoading ? <LoaderAnimation width={20} height={20} isOne={true} /> : translationMix(['account', 'userData', 'btn', '0'])}
                                    </button>
                                </form>

                                <form autoComplete={"off"} noValidate onSubmit={(event) => { event.preventDefault(); validateUserPassword() }} className={styles.verticalRight}>
                                    <div className={styles.text1}>{translationMix(['account', 'userData', 'subTitle2'])}</div>
                                    <div className={`${styles.error}`} ref={passwordError}></div>
                                    <div className={styles.horizontal}>
                                        <div className={styles.vertical}>
                                            <div className={styles.inputContainer}>
                                                <input ref={inputsRefs.current[6]} className={styles.input} type={"password"} required onInput={(event) => { hideLabel(event, 4); showHideOkReq(event, 4); }} />
                                                <div className={styles.labelContainer}>
                                                    <div className={styles.textReq} data-label={4}>{translationMix(['account', 'userData', 'labels', '0'])}</div>
                                                </div>
                                                <div className={styles.iconOk} data-ok={4} />
                                            </div>
                                            <div className={styles.inputContainer}>
                                                <input ref={inputsRefs.current[7]} className={styles.input} type={"password"} required onInput={(event) => { hideLabel(event, 5); showHideOkReq(event, 5); }} />
                                                <div className={styles.labelContainer}>
                                                    <div className={styles.textReq} data-label={5}>{translationMix(['account', 'userData', 'labels', '1'])}</div>
                                                </div>
                                                <div className={styles.iconOk} data-ok={5} />
                                            </div>
                                            <div className={styles.inputContainer}>
                                                <input ref={inputsRefs.current[8]} className={styles.input} type={"password"} required onInput={(event) => { hideLabel(event, 6); showHideOkReq(event, 6); }} />
                                                <div className={styles.labelContainer}>
                                                    <div className={styles.textReq} data-label={6}>{translationMix(['account', 'userData', 'labels', '2'])}</div>
                                                </div>
                                                <div className={styles.iconOk} data-ok={6} />
                                            </div>
                                            <button type={"submit"} className={styles.btn}>
                                                {isLoadingPassword ? <LoaderAnimation width={20} height={20} isOne={true} /> : translationMix(['account', 'userData', 'btn', '1'])}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserData;