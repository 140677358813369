import { useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getCookieValue } from '../../Helpers/getCookieValue';
import { translationMix } from '../../Helpers/translationMix';
import styles from './cookie.module.scss'

export function Coookie() {
    const { lang } = useParams()
    const containerRef = useRef(null)
    const isAccept = getCookieValue("isCookieAccept")

    function hideContainer() {
        if (containerRef) {
            var now = new Date();
            var time = now.getTime();
            var expireTime = time + 1000 * 36000 * 360;
            now.setTime(expireTime);
            document.cookie = `isCookieAccept=true; Path=/; SameSite=None; Secure; expires=${now.toUTCString()}`;
            containerRef.current.classList.remove(styles.animationGoUp)
            containerRef.current.classList.add(styles.animationGoDown)
        }
    }

    return (
        <>
            {isAccept === "true" ? null :
                <div ref={containerRef} className={`${styles.bottomContainer} ${styles.animationGoUp}`}>
                    <div className={styles.text}>{translationMix(['footer','cookie','text'])} <Link to={`/${lang}/privacy-policy`}>{translationMix(['footer','cookie','link'])}</Link></div>
                    <div className={styles.button} onClick={() => { hideContainer() }}>{translationMix(['footer','cookie','btn'])}</div>
                </div>}
        </>
    );
}

export default Coookie;